@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff') format('woff'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.woff2') format('woff2'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.woff') format('woff'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.woff2') format('woff2'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.woff') format('woff'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-600.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.woff') format('woff'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-800.woff2') format('woff2'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-800.woff') format('woff'), url('/fonts/inter-v12-cyrillic_cyrillic-ext_greek_greek-ext_latin_latin-ext_vietnamese-800.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  box-sizing: border-box;
}
html,
body,
#__next {
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100%;
}
html {
  font-size: 75%;
}
body {
  font-size: 1.33333333rem;
  line-height: 1.5;
}
.HIDE_DEBUG {
  display: none;
}
.view__sidebar {
  background: #444;
}
.view__toolbar {
  background: #efefef;
}
.view__main {
  background: #fff;
}
@font-face {
  font-family: 'headlinehunter-icons';
  src: url('/icons/headlinehunter-icons.ttf') format('truetype'), url('/icons/headlinehunter-icons.woff') format('woff'), url('/icons/headlinehunter-icons.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.iconLike,
[class^="icon:"]::before,
[class*=" icon:"]::before,
i,
.can\:toggle .viewMenu__sectionTitle::after,
.filterTag__value::after,
.switch__toggleThumb::before {
  font-family: 'headlinehunter-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 1.5rem;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
i {
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
}
.icon\:add::before {
  content: "\e968";
}
.icon\:add_box::before {
  content: "\e969";
}
.icon\:sort_by_alpha::before {
  content: "\e924";
}
.icon\:new::before {
  content: "\e900";
}
.icon\:file_download::before {
  content: "\e923";
}
.icon\:settings::before {
  content: "\e954";
}
.icon\:add_circle::before {
  content: "\e901";
}
.icon\:add_circle_outline::before {
  content: "\e902";
}
.icon\:block::before {
  content: "\e903";
}
.icon\:clear::before {
  content: "\e904";
}
.icon\:content_paste::before {
  content: "\e96a";
}
.icon\:dashboard::before {
  content: "\e96b";
}
.icon\:home::before {
  content: "\e96d";
}
.icon\:delete::before {
  content: "\e93b";
}
.icon\:delete_outline::before {
  content: "\e955";
}
.icon\:content_copy::before {
  content: "\e925";
}
.icon\:save::before {
  content: "\e926";
}
.icon\:sort::before {
  content: "\e927";
}
.icon\:file_copy::before {
  content: "\e928";
}
.icon\:storage::before {
  content: "\e905";
}
.icon\:format_align_left::before {
  content: "\e906";
}
.icon\:format_list_bulleted::before {
  content: "\e947";
}
.icon\:insert_chart::before {
  content: "\e907";
}
.icon\:calendar::before {
  content: "\e908";
}
.icon\:done::before {
  content: "\e93c";
}
.icon\:globe::before {
  content: "\e92c";
}
.icon\:globe_alt::before {
  content: "\e948";
}
.icon\:visibility_on::before {
  content: "\e909";
}
.icon\:style::before {
  content: "\e90a";
}
.icon\:tune::before {
  content: "\e90b";
}
.icon\:sun::before {
  content: "\e92a";
}
.icon\:moon::before {
  content: "\e929";
}
.icon\:apps::before {
  content: "\e90c";
}
.icon\:arrow_drop_down::before {
  content: "\e90d";
}
.icon\:arrow_drop_up::before {
  content: "\e90e";
}
.icon\:arrow_forward::before {
  content: "\e90f";
}
.icon\:arrow_back::before {
  content: "\e944";
}
.icon\:arrow_upward::before {
  content: "\e93a";
}
.icon\:arrow_downward::before {
  content: "\e945";
}
.icon\:menu::before {
  content: "\e910";
}
.icon\:vert_dots::before {
  content: "\e911";
}
.icon\:unfold_less::before {
  content: "\e913";
}
.icon\:unfold_more::before {
  content: "\e914";
}
.icon\:person::before {
  content: "\e915";
}
.icon\:grid::before {
  content: "\e96c";
}
.icon\:sentiment_neutral::before {
  content: "\e916";
}
.icon\:sentiment_very_dissatisfied::before {
  content: "\e917";
}
.icon\:sentiment_very_satisfied::before {
  content: "\e918";
}
.icon\:star::before {
  content: "\e919";
}
.icon\:star_outline::before {
  content: "\e91a";
}
.icon\:bug_report::before {
  content: "\e94a";
}
.icon\:bookmarks::before {
  content: "\e93e";
}
.icon\:bookmark::before {
  content: "\e91b";
}
.icon\:bookmark_outline::before {
  content: "\e91c";
}
.icon\:check_circle::before {
  content: "\e91e";
}
.icon\:remove_circle::before {
  content: "\e935";
}
.icon\:exit::before {
  content: "\e92b";
}
.icon\:table::before {
  content: "\e95c";
}
.icon\:translate::before {
  content: "\e92f";
}
.icon\:globe::before {
  content: "\e92c";
}
.icon\:globe_alt::before {
  content: "\e948";
}
.icon\:public::before {
  content: "\e952";
}
.icon\:lock_open::before {
  content: "\e92d";
}
.icon\:lock_outline::before {
  content: "\e92e";
}
.icon\:search::before {
  content: "\e91f";
}
.icon\:view_agenda::before {
  content: "\e920";
}
.icon\:view_headline::before {
  content: "\e921";
}
.icon\:logout::before {
  content: "\e930";
}
.icon\:saved_search::before {
  content: "\e931";
}
.icon\:filter::before {
  content: "\e932";
}
.icon\:search_off::before {
  content: "\e933";
}
.icon\:visibility_off::before {
  content: "\e922";
}
.icon\:text_snippet::before {
  content: "\e943";
}
.icon\:picture_as_pdf::before {
  content: "\e939";
}
.icon\:bubbles::before {
  content: "\e946";
}
.icon\:bubble_chart::before {
  content: "\e95a";
}
.icon\:book::before {
  content: "\e956";
}
.icon\:leaderboard::before {
  content: "\e949";
}
.icon\:magic::before {
  content: "\e957";
}
.icon\:wand::before {
  content: "\e958";
}
.icon\:insights::before {
  content: "\e94d";
}
.icon\:report::before {
  content: "\e94c";
}
.icon\:suspect::before {
  content: "\e94f";
}
.icon\:trusted::before {
  content: "\e950";
}
.icon\:edit::before {
  content: "\e94e";
}
.icon\:construction::before {
  content: "\e941";
}
.icon\:build::before {
  content: "\e953";
}
.icon\:reload::before {
  content: "\e91d";
}
.icon\:magic1::before {
  content: "\e951";
}
.icon\:file_pdf::before {
  content: "\e95d";
}
.icon\:refresh::before {
  content: "\e912";
}
.icon\:rss_feed::before {
  content: "\e95e";
}
.icon\:update_daterange::before {
  content: "\e962";
}
.icon\:facebook::before {
  content: "\e964";
}
.icon\:facebook_box::before {
  content: "\e966";
}
.icon\:twitter::before {
  content: "\e967";
}
.icon\:telegram::before {
  content: "\e965";
}
.icon\:trending_up::before {
  content: "\e973";
}
.icon\:location::before {
  content: "\e971";
}
.icon\:cart::before {
  content: "\e972";
}
.icon\:business::before {
  content: "\e96e";
}
.icon\:bullseye::before {
  content: "\e970";
}
.icon\:info_outline::before {
  content: "\e96f";
}
.icon\:share::before {
  content: "\e974";
}
.icon\:mail_outline::before {
  content: "\e975";
}
.icon\:whatsapp::before {
  content: "\e977";
}
.icon\:link::before {
  content: "\e976";
}
.icon\:launch::before {
  content: "\e979";
}
.icon\:chevron_back::before {
  content: "\e978";
}
.icon\:newspaper::before {
  content: "\e97a";
}
.icon\:chevron_down {
  transform: rotate(-90deg);
}
.icon\:chevron_down:before {
  content: "\e978";
}
.icon\:chevron_up {
  transform: rotate(90deg);
}
.icon\:chevron_up:before {
  content: "\e978";
}
body {
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  color: #1c2c63;
}
a:not(.blockLink) {
  color: #253b85;
}
a:not(.blockLink):hover {
  color: #2e4cb1;
}
a.blockLink {
  color: inherit;
  text-decoration: inherit;
}
.timestamp {
  letter-spacing: 0.05em;
}
.timestamp b {
  font-weight: bold;
  opacity: 0.25;
}
strong {
  font-weight: bold;
}
.nobr {
  white-space: nowrap;
}
.font--label {
  font-size: 1.25rem;
  line-height: 1.26666667;
}
.font--mini {
  font-size: 1rem;
  line-height: 1.33333333;
}
.font--small {
  font-size: 1.16666667rem;
  line-height: 1.28571429;
}
.font--normal {
  font-size: 1.33333333rem;
  line-height: 1.5;
}
.font--medium {
  font-size: 1.5rem;
  line-height: 1.33333333;
}
.font--large {
  font-size: 1.75rem;
  line-height: 1.33333333;
}
.font--heading {
  font-size: 2.33333333rem;
  line-height: 1.28571429;
}
.font--display {
  font-size: 3.33333333rem;
  line-height: 1.5;
}
.bigNumber {
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 1;
}
.inputLike,
input,
select {
  display: inline-flex;
  position: relative;
  font-size: 1em;
  line-height: 1;
  padding: 0.5em 0.75em;
  height: 2.625em;
  border: 0.08333333rem solid #5f82f3;
  border-radius: 0.5em;
}
.inputLike.input\:small,
input.input\:small,
select.input\:small {
  height: 2.33333333rem;
  padding: 0.25em 0.375em;
  font-size: 1rem;
  line-height: 1.33333333;
  border-radius: 0.66666667rem;
}
input,
select {
  font-family: 'Inter', sans-serif;
  transition: color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
input:focus,
select:focus {
  border-color: #355cdd;
}
input::placeholder {
  color: #abafb8;
}
textarea {
  width: 100%;
  font-family: 'Inter', sans-serif;
  padding: 0.5em 0.75em;
}
.form__field {
  display: flex;
  flex-flow: column wrap;
  align-items: stretch;
}
.form__errors {
  color: #b24334;
  font-size: 1.16666667rem;
  line-height: 1.28571429;
  min-height: 2rem;
  display: flex;
  align-items: center;
  align-self: flex-start;
}
.field__label {
  display: block;
  padding: 1.66666667rem 0rem 0.33333333rem;
  font-size: 1.25rem;
  line-height: 1.26666667;
  font-weight: 500;
  color: #444;
}
.field__errors {
  color: #b24334;
  font-size: 1.08333333rem;
  line-height: 1.38461538;
  min-height: 2rem;
  display: flex;
  align-items: center;
}
.field__input--checkbox,
.field__input--radio {
  display: flex;
}
.field__input--checkbox input,
.field__input--radio input {
  height: 1.33333333rem;
  margin-right: 0.66666667rem;
}
.field.has\:pendingChanges input {
  background-color: blue;
  border-color: lightblue;
}
.field.has\:error input,
.field.has\:error .select__control {
  background-color: #fcf1f0;
  border-color: #b24334;
}
.field .file.has\:error input,
.field .file.has\:error .select__control {
  background-color: #fcf1f0;
  border-color: #b24334;
}
.field.has\:error {
  position: relative;
}
.field.has\:error .field__errors {
  position: absolute;
  right: 0;
  bottom: 3.5rem;
}
.field.has\:error label {
  color: #b24334;
}
input[type='time'] {
  padding: 0.66666667rem 0.83333333rem;
}
input[type='time']::-webkit-datetime-edit-hour-field,
input[type='time']::-webkit-datetime-edit-minute-field,
input[type='time']::-webkit-datetime-edit-ampm-field {
  padding: 0rem 0.25rem;
}
input[type='time']::-webkit-clear-button {
  display: none;
}
input[type='time']::-webkit-inner-spin-button {
  display: none;
}
input[type='number'] {
  border-radius: 0.5em;
}
.styledScrollbar {
  scrollbar-width: thin;
  scrollbar-color: #9cb3fc transparent;
}
.styledScrollbar::-webkit-scrollbar {
  width: 0.875em;
}
.styledScrollbar::-webkit-scrollbar-track {
  background-color: inherit;
}
.styledScrollbar::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.styledScrollbar::-webkit-scrollbar-thumb {
  background-color: #9cb3fc;
  background-clip: padding-box;
  border: 0.25em solid transparent;
  border-radius: 0.4375em;
  min-height: 6.25em;
}
.noScroll {
  overflow: hidden;
}
.noScrollbar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.noScrollbar::-webkit-scrollbar {
  width: 1px;
}
.noScrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.noScrollbar::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.noScrollbar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
button {
  font-family: 'Inter', sans-serif;
  border: none;
  cursor: pointer;
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.buttonLike,
.button {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  white-space: nowrap;
  cursor: pointer;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: transform 0.1s ease-out, color 0.15s ease-out, background 0.25s ease-out, border-color 0.25s ease-out;
  border-radius: 0.8em;
}
.buttonLike::before,
.button::before {
  transition: transform 0.1s ease-out, color 0.15s ease-out;
}
a.buttonLike,
a.button {
  text-decoration: none;
}
.buttonLike:focus,
.button:focus {
  outline: 0;
}
.buttonLike:not( .has\:iconOnly)::before,
.button:not( .has\:iconOnly)::before {
  margin-right: 0.5em;
  margin-left: -0.25em;
}
.buttonLike.has\:iconOnly,
.button.has\:iconOnly {
  padding: 0 !important;
}
.buttonLike.has\:iconOnly::before,
.button.has\:iconOnly::before {
  margin: 0;
}
.buttonLike + .buttonLike,
.button + .button {
  margin-left: 0.25em;
}
.buttonLike.is\:disabled:hover,
.buttonLike.is\:disabled:active,
.button.is\:disabled:hover,
.button.is\:disabled:active {
  transform: none !important;
}
.button {
  display: inline-flex;flex-flow: row nowrap;justify-content: center;align-items: center;font-family: 'Inter', sans-serif;cursor: pointer;backface-visibility: hidden;transform: translate3d(0, 0, 0);transition: transform 0.1s ease-out, color 0.15s ease-out, background 0.25s ease-out, border-color 0.25s ease-out;border-radius: 0.25rem;
}
a.button {
  text-decoration: none;
}
.button:focus {
  outline: 0;
}
.button:active {
  transform: translate3d(0, 0.08333333rem, 0);
}
.button:not( .has\:iconOnly )::before {
  margin-right: 0.5em;margin-left: -0.25em;
}
.button.has\:iconOnly {
  padding: 0 !important;
}
.button.has\:iconOnly::before {
  margin: 0;
}
.buttonGroup {
  display: inline-flex;
  margin: 0rem 0.33333333rem;
}
.buttonGroup:last-child {
  margin-right: 0;
}
.buttonGroup:first-child {
  margin-left: 0;
}
.buttonGroup + .buttonGroup {
  margin-left: 0;
}
.buttonGroup--small .button {
  height: 1.75em;
}
.buttonGroup--small .button.is\:active {
  background: #fff;
  color: #355cdd;
}
.buttonGroup--small .button::before {
  color: #5f82f3;
}
.buttonGroup .button {
  border-radius: 0;
}
.buttonGroup .button:not(:first-child) {
  margin: 0rem 0rem 0rem 0.08333333rem;
}
.buttonGroup .button:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.buttonGroup .button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.buttonGroup .button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@media (min-width: 768px) {
  [class*="columns:"] {
    display: grid;
    grid-template-rows: 1fr;
    column-gap: 2rem;
  }
  .columns\:2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .columns\:3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .columns\:4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .columns\:5 {
    grid-template-columns: repeat(5, 1fr);
  }
  .columns\:6 {
    grid-template-columns: repeat(6, 1fr);
  }
  .columns\:7 {
    grid-template-columns: repeat(7, 1fr);
  }
  .columns\:8 {
    grid-template-columns: repeat(8, 1fr);
  }
  .columns\:9 {
    grid-template-columns: repeat(9, 1fr);
  }
  .columns\:10 {
    grid-template-columns: repeat(10, 1fr);
  }
  .columns\:11 {
    grid-template-columns: repeat(11, 1fr);
  }
  .columns\:12 {
    grid-template-columns: repeat(12, 1fr);
  }
  .columns\:1\+11 {
    grid-template-columns: 1fr 11fr;
  }
  .columns\:2\+10 {
    grid-template-columns: 2fr 10fr;
  }
  .columns\:3\+9 {
    grid-template-columns: 3fr 9fr;
  }
  .columns\:4\+8 {
    grid-template-columns: 4fr 8fr;
  }
  .columns\:5\+7 {
    grid-template-columns: 5fr 7fr;
  }
  .columns\:6\+6 {
    grid-template-columns: 6fr 6fr;
  }
  .columns\:7\+5 {
    grid-template-columns: 7fr 5fr;
  }
  .columns\:8\+4 {
    grid-template-columns: 8fr 4fr;
  }
  .columns\:9\+3 {
    grid-template-columns: 9fr 3fr;
  }
  .columns\:10\+2 {
    grid-template-columns: 10fr 2fr;
  }
  .columns\:11\+1 {
    grid-template-columns: 11fr 1fr;
  }
  .col\:1 {
    grid-column: span 1;
  }
  .col\:2 {
    grid-column: span 2;
  }
  .col\:3 {
    grid-column: span 3;
  }
  .col\:4 {
    grid-column: span 4;
  }
  .col\:5 {
    grid-column: span 5;
  }
  .col\:6 {
    grid-column: span 6;
  }
  .col\:7 {
    grid-column: span 7;
  }
  .col\:8 {
    grid-column: span 8;
  }
  .col\:9 {
    grid-column: span 9;
  }
  .col\:10 {
    grid-column: span 10;
  }
  .col\:11 {
    grid-column: span 11;
  }
  .col\:12 {
    grid-column: span 12;
  }
}
.button {
  font-size: 1em;
  height: 2.5em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  border-radius: 0.8em;
}
.button.has\:iconOnly {
  width: 2.5em;
}
.button::before {
  font-size: 1em;
}
.button.has\:iconOnly {
  padding: 0 !important;
}
.button.has\:iconOnly::before {
  margin: 0 !important;
}
button.has\:iconOnly {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button {
  font-weight: 500;
  color: #fff;
  background: #253b85;
}
.button::before {
  color: #9cb3fc;
}
.button:hover {
  color: #fff;
  background: #2e4cb1;
}
.button:hover::before {
  color: #809dfc;
}
.button:active {
  color: #fff;
  background: #253b85;
}
.button:active::before {
  color: #355cdd;
}
.button:focus-visible {
  outline: 0.0625em solid #9cb3fc;
}
.button\:primary {
  color: #fff;
  background: #355cdd;
}
.button\:primary::before {
  color: #ccd8fc;
}
.button\:primary:hover {
  color: #fff;
  background: #5f82f3;
}
.button\:primary:hover::before {
  color: #ccd8fc;
}
.button\:primary:active {
  color: #fff;
  background: #5f82f3;
}
.button\:primary:active::before {
  color: #ccd8fc;
}
.button\:primary:focus-visible {
  outline: 0.0625em solid #5f82f3;
  outline-offset: 0.0625em;
}
.button\:primary.is\:disabled {
  background-color: rgba(53, 92, 221, 0.5);
}
.button\:outline {
  color: #253b85;
  background: transparent;
  border: 0.0625em solid currentColor;
}
.button\:outline:hover {
  color: #2e4cb1;
  background: transparent;
}
.button\:outline:active {
  color: #253b85;
  background: transparent;
}
.button\:outline:focus-visible {
  outline: none;
  background-color: #253b85;
  color: #fff;
}
.button\:primary.button\:outline {
  color: #355cdd;
}
.button\:primary.button\:outline:hover {
  background-color: transparent;
}
.button\:primary.button\:outline:active {
  background-color: transparent;
}
.button\:primary.button\:outline:focus-visible {
  background-color: rgba(46, 76, 177, 0.1);
}
.button\:primary.button\:outline.is\:disabled {
  background-color: transparent;
  color: rgba(53, 92, 221, 0.5);
}
.button\:danger {
  color: #fff;
  background: #b24334;
}
.button\:danger:hover,
.button\:danger:active {
  background: #c33623;
}
.button\:success {
  color: #fff;
  background: #2b6349;
}
.button\:success:hover,
.button\:success:active {
  background: #2b6349;
}
.button\:naked {
  background: transparent !important;
  border: none !important;
}
.button\:small {
  height: 2.33333333rem;
  padding: 1.16666667rem 1.33333333rem 1.16666667rem 1.16666667rem;
  font-size: 1rem;
  line-height: 1.33333333;
  border-radius: 0.5em;
}
.button\:small::before {
  font-size: 1.16666667rem;
  line-height: 1;
}
.button\:small:focus-visible {
  outline: 0.0625em solid #9cb3fc;
  outline-offset: 0.125em;
}
.button\:small.has\:iconOnly {
  width: 2.33333333rem;
}
.button.is\:loading {
  padding-left: 1.2em;
  cursor: default;
}
.button.is\:disabled {
  pointer-events: none;
}
.button.is\:disabled::before {
  opacity: 0.6;
}
.button .spinner {
  margin: 0rem 0.66666667rem 0rem 0rem;
}
.textInput {
  align-items: center;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  display: inline-flex;
  position: relative;
  font-size: 1em;
  line-height: 1;
  padding: 0.5em 0.75em;
  height: 2.625em;
  border: 0.08333333rem solid #5f82f3;
  border-radius: 0.5em;
  padding-left: 0;
  padding-right: 0;
}
.textInput.input\:small {
  height: 2.33333333rem;
  padding: 0.25em 0.375em;
  font-size: 1rem;
  line-height: 1.33333333;
  border-radius: 0.66666667rem;
}
.textInput.icon::before {
  margin-right: 0.5rem;
}
.textInput .input {
  width: 100%;
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent !important;
}
.textInput .input:focus {
  outline: none;
}
.textInput__visibilityToggle {
  position: absolute;
  background: transparent;
  height: 2.625em;
  color: #253b85;
  right: 0;
  height: 100%;
  padding: 0rem 0.66666667rem;
}
.textInput__visibilityToggle:focus {
  background-color: #ccd8fc;
  outline: none;
}
.textInput:focus-within .textInput:has(:focus-visible) {
  outline: 2px solid #d5d6db;
  outline-offset: 1px;
}
.dialogOverlay {
  z-index: 399;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0px;
  animation: 150ms cubic-bezier(0.16, 1, 0.3, 1) 0s 1 normal none running dialog-overlay-enter;
}
.dialog {
  position: fixed;
  z-index: 400;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 28.125em;
  max-height: 85vh;
  padding: 1.5em 1.5em;
  background-color: #fff;
  border-radius: 1.5em;
  box-shadow: 0em 0.75em 0.5em rgba(17, 29, 66, 0.1), 0em 1.25em 1em rgba(37, 59, 133, 0.1);
  animation: 150ms cubic-bezier(0.16, 1, 0.3, 1) 0s 1 normal none running dialog-enter;
}
.dialog__title {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1.33333333;
}
.dialog__content {
  margin-top: 1.5rem;
}
.dialog__content input,
.dialog__content .select {
  width: 100%;
}
.dialog__actions {
  display: flex;
  justify-content: center;
  margin-top: 1em;
}
.dialog__action + .dialog__action {
  margin-left: 0.75em;
}
@keyframes dialog-overlay-enter {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes dialog-enter {
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
.pageFooter {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  font-size: 1rem;
  line-height: 2;
  padding: 1.33333333rem 2rem;
}
.pageFooter__content {
  width: 100%;
  max-width: 100vh;
  display: flex;
  justify-content: space-between;
}
.pageFooter__logo {
  display: flex;
  justify-content: center;
}
.pageFooter__logo svg {
  height: 2rem;
}
.pageFooter__copy {
  display: flex;
  justify-content: center;
}
.pageFooter__links {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.pageFooter__link {
  margin: 0rem 0.66666667rem;
}
@media (max-width: 767px) {
  .pageFooter__content {
    flex-flow: column;
  }
  .pageFooter__content > * {
    margin-top: 0.33333333rem;
  }
}
@media (min-width: 768px) {
  .pageFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
  }
}
.floatyBox {
  display: flex;
  flex-flow: column;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.34);
  box-shadow: 0 0.1em 0.25em rgba(37, 59, 133, 0.05), 0 0.25em 0.45em rgba(37, 59, 133, 0.05);
  border-radius: 1.5em;
  transition-property: box-shadow, background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 0.5em 2em -0.1em rgba(42, 47, 56, 0.05);
  padding: 2.66666667rem 2.66666667rem 2.66666667rem 3.16666667rem;
}
.floatyBox__title {
  font-size: 1.66666667rem;
  line-height: 0.9;
  font-weight: 600;
}
.floatyBox__text {
  font-size: 1.16666667rem;
  line-height: 1.14285714;
  margin: 0.83333333rem 0rem 0.83333333rem 0rem;
  font-weight: 500;
}
.floatyBox__action {
  align-self: flex-end;
  background: rgba(53, 92, 221, 0.1);
  border: 1px solid transparent;
  margin-top: 1.33333333rem;
}
.floatyBox__action:hover {
  border-color: transparent;
  background: rgba(53, 92, 221, 0.15);
  color: #2e4cb1 !important;
}
.floatyBox__action:focus {
  background: rgba(53, 92, 221, 0.3);
  color: #2e4cb1 !important;
  outline: 1px solid #355cdd;
  outline-offset: 0.1em;
}
.floatyBox__action:focus::before {
  color: #2e4cb1 !important;
}
.floatyBox:hover,
.floatyBox:focus-within {
  box-shadow: 0 0.5em 2em -0.1em rgba(42, 47, 56, 0.1);
  background-color: rgba(255, 255, 255, 0.4);
}
@media (max-width: 767px) {
  .floatyBox {
    box-shadow: none !important;
    backdrop-filter: none;
    background: none;
    transition: none;
    padding: 2rem;
    margin: 0;
  }
}
.viewHeader {
  background: #fff;
  color: #1c2c63;
  box-shadow: 0 0 0.75em rgba(28, 44, 99, 0.25);
}
.viewHeader__masthead {
  visibility: hidden;
  display: none;
  padding-left: 0.75em;
}
@media (min-width: 1024px) {
  .viewHeader__masthead {
    display: flex;
    visibility: visible;
    align-items: center;
  }
}
@media (min-width: 1366px) {
  .viewHeader__masthead {
    display: flex;
    visibility: visible;
    align-items: center;
  }
}
.viewHeader__logo {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 1024px) {
  .viewHeader__logo {
    width: 7.5em;
  }
}
@media (min-width: 1366px) {
  .viewHeader__logo {
    width: 10em;
  }
}
.viewHeader__logo img {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.viewHeader__tagline {
  display: none;
  flex-flow: column nowrap;
  justify-content: center;
  border-left: 1px solid #5f82f3;
  padding: 0.1875em 0.5em 0.1875em 0.9375em;
}
@media (min-width: 1366px) {
  .viewHeader__tagline {
    display: flex;
  }
}
.viewHeader__taglineSub {
  color: #1c2c63;
  font-size: 0.75em;
  line-height: 1.25;
}
.viewHeader__taglineTitle {
  display: block;
  color: #253b85;
  font-weight: 700;
  line-height: 1.25;
}
.viewHeader__toolbar {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
}
@media (max-width: 1023px) {
  .viewHeader__toolbar {
    padding: 0.5em 0;
  }
}
@media (min-width: 1024px) {
  .viewHeader__toolbar {
    padding: 0.5em;
    padding-left: 0;
  }
}
.viewHeader__toggleOptions {
  background: none !important;
  color: #2e4cb1;
  padding: 0 0.75em;
  height: 100%;
  display: inline-flex;
  align-items: center;
}
.viewHeader__toggleOptions::before {
  font-size: 1.83333333rem;
  line-height: 1.09090909;
}
@media (min-width: 768px) {
  .viewHeader__toggleOptions {
    padding: 0 1em;
  }
}
@media (min-width: 1024px) {
  .viewHeader__toggleOptions--options {
    display: none !important;
    visibility: hidden;
  }
}
@media (min-width: 1366px) {
  .viewHeader__toggleOptions {
    display: none !important;
    visibility: hidden;
  }
}
.viewHeader__searchInput {
  flex: 1 1 100%;
  overflow-x: auto;
  overflow-y: hidden;
  grid-area: middle;
}
.viewHeader__searchMode {
  grid-area: left;
}
.viewHeader__searchSubmit {
  grid-area: right;
}
.viewHeader__searchBox {
  flex: 1 1 100%;
  height: 3.5rem;
  display: grid;
  grid-template-areas: 'left middle right';
  grid-template-columns: minmax(0, auto) minmax(auto, 1fr) minmax(0, auto);
  position: relative;
  padding: 0;
  background-color: #fff;
  border: 0.0625em solid #809dfc;
  border-radius: 0.8em;
}
@media (min-width: 1024px) {
  .viewHeader__searchBox {
    margin-left: 0.33333333rem;
    margin-right: 0.5rem;
    grid-template-columns: minmax(0, auto) minmax(auto, 1fr) minmax(0, 8.9rem);
  }
}
.viewHeader__searchBox.has\:proSearch .proSearch {
  display: flex;
}
.viewHeader__searchBox.has\:suggestions .searchSuggestions {
  display: block;
}
@media (min-width: 1024px) {
  .viewHeader__searchBox.has\:suggestions .viewHeader__searchSubmit {
    border-bottom-right-radius: 0.8em;
  }
}
.viewHeader__searchActions {
  padding: 0.084rem 0;
}
.viewHeader__searchActions .button {
  border-radius: 0.15em;
  height: auto;
}
.viewHeader__searchActions .button b {
  font-weight: bold;
  font-size: 1.16666667rem;
  line-height: 1;
}
.viewHeader__searchActions .button.is\:active {
  background: #fff;
  color: #355cdd;
}
.viewHeader__searchActions .button::before {
  color: #5f82f3;
}
@media (max-width: 1023px) {
  .viewHeader__searchActions {
    display: none;
    visibility: hidden;
    right: 1em;
    top: 1em;
    left: 1em;
    padding: 1.5em 1em;
    background-color: #fff !important;
    border-radius: 0.8em;
    box-shadow: 0 0 1em rgba(37, 59, 133, 0.07), 0 0.25em 0.25em rgba(17, 29, 66, 0.15), 0 0.75em 0.75em rgba(28, 44, 99, 0.1);
  }
  .viewHeader__searchActions.is\:open {
    display: block;
    visibility: visible;
  }
  .viewHeader__searchActions .buttonGroup {
    flex-flow: column nowrap;
    width: 100%;
    margin: 0;
    gap: 0.2em 0;
  }
  .viewHeader__searchActions .button {
    font-size: 1.33333333rem;
    line-height: 1.5;
    background-color: transparent;
    color: #1c2c63;
    width: 100%;
    padding: 0.5em 1em;
    margin: 0.2em 0 0 0;
    display: block;
    text-align: left;
    border-radius: 0.45em !important;
  }
  .viewHeader__searchActions .button::before,
  .viewHeader__searchActions .button b {
    margin-right: 0.5em;
    color: #809dfc;
  }
  .viewHeader__searchActions .button span {
    white-space: pre-wrap;
  }
  .viewHeader__searchActions .button.is\:active {
    background-color: #5f82f3;
    color: #fff;
  }
  .viewHeader__searchActions .button.is\:active::before,
  .viewHeader__searchActions .button.is\:active b {
    color: #ccd8fc;
  }
}
@media (min-width: 1024px) {
  .viewHeader__searchActions {
    min-height: 100%;
    align-self: stretch;
    display: flex;
    align-items: stretch;
    margin-left: auto;
  }
  .viewHeader__searchActions .button {
    height: 100%;
    width: 2.66666667rem;
    padding: 0.66em 1.25em;
    justify-content: center;
    background-color: #e9eefc;
    color: #2e4cb1;
    border-radius: 0.45em;
    transition: color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), border-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), background-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1);
  }
  .viewHeader__searchActions .button::before {
    margin: 0;
    padding: 0;
  }
  .viewHeader__searchActions .button b {
    font-size: 1.08333333rem;
    line-height: 1;
  }
  .viewHeader__searchActions .button span {
    display: none;
  }
  .viewHeader__searchActions .button.is\:active {
    background-color: #5f82f3;
    color: #fff;
  }
  .viewHeader__searchActions .button.is\:active::before,
  .viewHeader__searchActions .button.is\:active b {
    color: #fff;
  }
  .viewHeader__searchActions .button:not(.is\:active):hover {
    background-color: #809dfc;
    color: #fff;
  }
  .viewHeader__searchActions .button:not(.is\:active):hover::before {
    color: #fff;
  }
  .viewHeader__searchActions .buttonGroup {
    height: 100%;
    align-items: stretch;
    background-color: #e9eefc;
    border-radius: 0.45em;
  }
  .viewHeader__searchActions .buttonGroup .button {
    border-radius: 0;
  }
  .viewHeader__searchActions .buttonGroup .button:first-of-type {
    border-top-left-radius: 0.45em;
    border-bottom-left-radius: 0.45em;
  }
  .viewHeader__searchActions .buttonGroup .button:last-of-type {
    border-top-right-radius: 0.45em;
    border-bottom-right-radius: 0.45em;
  }
}
.extendedSearch {
  background-color: #e6eaf8;
}
.extendedSearch__header,
.extendedSearch__filters,
.extendedSearch__actions {
  padding: 1em;
}
.extendedSearch__title {
  font-size: 1.75em;
  line-height: 1.23809524;
  font-weight: bold;
}
.extendedSearch__desc {
  margin-top: 0.375em;
  color: #5f82f3;
}
@media (max-width: 1023px) {
  .extendedSearch__header,
  .extendedSearch__filters {
    margin-left: auto;
    margin-right: auto;
    max-width: 40em;
  }
}
@media (min-width: 768px) {
  .extendedSearch__header {
    max-width: 100%;
    padding: 1em 1.5em;
  }
  .extendedSearch__filters {
    max-width: 100%;
    display: flex;
    gap: 3em;
    padding: 1em 1.5em;
  }
  .extendedSearch__filters > * {
    flex: 1 1 100%;
  }
}
@media (min-width: 1024px) {
  .extendedSearch {
    padding: 0.5em;
    display: grid;
    grid-template-columns: 1fr 16em;
    grid-template-rows: 2fr 1.5fr;
    grid-template-areas: "filters header" "filters actions";
    border: 0.0625em solid #809dfc;
    border-top-width: 0;
    border-bottom-left-radius: 0.8em;
    border-bottom-right-radius: 0.8em;
  }
  .extendedSearch__header {
    grid-area: header;
    place-self: center;
    text-align: center;
  }
  .extendedSearch__filters {
    grid-area: filters;
  }
  .extendedSearch__actions {
    grid-area: actions;
  }
  .show\:extendedSearch .extendedSearch {
    border-color: #5f82f3;
  }
}
@media (min-width: 1366px) {
  .extendedSearch {
    grid-template-columns: 1fr 20em;
  }
}
.extendedSearch__filters {
  padding-bottom: 8em;
}
.extendedSearch__filtersTitle {
  font-size: 1.5em;
  line-height: 1.33333333;
  font-weight: 600;
  margin-top: 1em;
}
@media (min-width: 1024px) {
  .extendedSearch__filters {
    padding-bottom: 1.5em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column dense;
    grid-gap: 0 1.5em;
  }
}
@media (min-width: 1600px) {
  .extendedSearch__filters {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
  .extendedSearch__filtersTitle {
    display: block;
    visibility: visible;
    margin-top: 0;
  }
  .extendedSearch__filtersGroup {
    display: block;
  }
  .extendedSearch__filtersGroup--filters {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, min-content);
    grid-gap: 0 1.5em;
  }
  .extendedSearch__filtersGroup--filters .extendedSearch__filtersTitle {
    grid-column: 1 / span 2;
  }
}
.extendedSearch__error {
  background-color: #fcf1f0;
  border: 0.0625em solid #b24334;
  padding: 0.5em 1em;
  color: #b24334;
  text-align: left;
  border-radius: 0.8em;
}
.extendedSearch__errorTitle {
  font-weight: 600;
}
.extendedSearch__errorMessages {
  list-style: outside;
  margin-top: 0.5em;
  margin-left: 1em;
}
@media (max-width: 1023px) {
  .extendedSearch__actions {
    display: flex;
    justify-content: center;
    background-color: #e6eaf8;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 500;
    box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.35);
  }
}
@media (min-width: 1024px) {
  .extendedSearch__actions {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 1.75em;
    margin-top: auto;
  }
  .extendedSearch__actions > .button {
    width: 100%;
    margin: 0.3125em 0 0 0 !important;
  }
}
.extendedSearch .field {
  padding: 0.5em;
}
@media (min-width: 1024px) {
  .extendedSearch .field {
    padding: 0.5em 0;
  }
}
.extendedSearch .field__label {
  font-weight: 500;
  color: #5f82f3;
  padding-top: 0.75em;
}
@media (min-width: 1024px) {
  .extendedSearch .field__label {
    padding-top: 0;
  }
}
.extendedSearch .field__input {
  width: 100%;
  color: inherit;
}
.extendedSearch .field__input::placeholder,
.extendedSearch .field__inputselect:invalid {
  opacity: 1;
}
.extendedSearch .field__input.is\:focus,
.extendedSearch .field__input:focus,
.extendedSearch .field__input:focus-within {
  border-color: #355cdd;
  outline: none;
}
.viewControls__menu {
  display: flex;
  justify-content: space-evenly;
  padding: 0.5em 0;
}
.viewControls__menuButton {
  color: #5f82f3;
  background-color: transparent;
}
.viewControls__menuButton.is\:active {
  color: #355cdd;
}
.viewControls__menuButton::before {
  margin-right: 0.5em;
}
.viewMenu {
  background-color: rgba(255, 255, 255, 0.4);
  overflow-x: hidden;
  box-shadow: 0 0 1em rgba(46, 76, 177, 0.1);
  display: flex;
  flex-flow: column nowrap;
  max-height: 100vH;
  position: relative;
  z-index: 300;
}
.viewMenu::before {
  content: '';
  display: block;
  position: absolute;
  z-index: 310;
  top: 0;
  left: 0;
  right: 0.5em;
  height: 0.625em;
  background-image: linear-gradient(to bottom, #fff 50%, rgba(255, 255, 255, 0));
}
.viewMenu__content {
  flex: 1 1 100%;
  overflow-y: auto;
  padding: 0.25em 0.75em;
}
.viewMenu__user {
  flex: 1 1 3.5em;
}
.viewMenu__section + .viewMenu__section {
  margin-top: 0.16666667rem;
}
.viewMenu__section a,
.viewMenu__sectionItemLabel {
  color: #1c2c63;
  text-decoration: none;
}
.viewMenu__sectionTitle {
  position: relative;
  z-index: 320;
  top: 0;
  background-color: transparent;
  border-radius: 0.45em;
  transition: color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), border-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), background-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1);
  height: 3.5em;
  padding: 0 1em 0 1.5em;
  display: flex;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.viewMenu__sectionTitle:hover {
  background-color: rgba(95, 130, 243, 0.4);
}
.can\:toggle .viewMenu__sectionTitle::after {
  font-family: 'headlinehunter-icons';
  content: "\e90d";
  color: currentColor;
  font-size: 1.33333333rem;
  line-height: 1;
  margin-left: 0rem;
}
.can\:toggle .viewMenu__sectionTitle::after {
  margin-left: auto;
}
.can\:toggle.is\:open .viewMenu__sectionTitle::after {
  content: "\e90e";
}
.is\:disabled .viewMenu__sectionTitle {
  cursor: default;
  color: #abafb8;
  background-color: transparent !important;
}
.viewMenu__sectionTitle .count {
  margin-left: 1em;
}
.viewMenu__sectionTitle i {
  margin-right: 0.5em;
}
.viewMenu__sectionTitle .link {
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.is\:active .viewMenu__sectionTitle,
.is\:active .viewMenu__sectionTitle a,
.is\:active .viewMenu__sectionTitle:hover,
.is\:active .viewMenu__sectionTitle:hover a {
  font-weight: 600;
  color: #fff;
  background-color: #809dfc;
}
.is\:active .viewMenu__sectionTitle::before,
.is\:active .viewMenu__sectionTitle a::before,
.is\:active .viewMenu__sectionTitle:hover::before,
.is\:active .viewMenu__sectionTitle:hover a::before {
  color: #fff;
}
.viewMenu__sectionItems {
  display: none;
  visibility: hidden;
  padding-top: 0.1875em;
  padding-bottom: 0.1875em;
}
.is\:open .viewMenu__sectionItems {
  display: block;
  visibility: visible;
}
.viewMenu__sectionItem {
  border-radius: 0.45em;
  background-color: transparent;
  transition: color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), border-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), background-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.viewMenu__sectionItem + .viewMenu__sectionItem {
  margin-top: 0.1875em;
}
.viewMenu__sectionItem:hover {
  color: #1c2c63;
  background-color: rgba(128, 157, 252, 0.3);
}
.viewMenu__sectionItem [class*="icon:"]::before {
  margin-right: 0.5em;
}
.viewMenu__sectionItem .count {
  margin-left: 0.5em;
  height: 1em;
  align-self: center;
}
.viewMenu__sectionItem .popoverTrigger {
  height: auto;
  background-color: transparent;
  display: inline-block;
  margin-left: auto;
  width: 2em;
  flex-shrink: 0;
}
.viewMenu__sectionItemLabel {
  display: flex;
  color: #1c2c63;
  border-radius: 0.45em;
  text-decoration: none;
  transition: color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), border-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), background-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1);
  padding: 0.75em 1em 0.75em 1.5em;
  font-size: 1.33333333rem;
  line-height: 1.5;
  text-align: initial;
  width: 100%;
  background: none;
}
.viewMenu__sectionItems--bookmarks .viewMenu__sectionItemLabel {
  padding-left: 1.2em;
}
.viewMenu__sectionItem .link,
.viewMenu__sectionItem .link .flex {
  display: flex;
  overflow: hidden;
  flex-grow: 1;
}
.viewMenu__sectionItem .link i,
.viewMenu__sectionItem .link .flex i {
  order: 0;
}
.viewMenu__sectionItem .link .label,
.viewMenu__sectionItem .link .flex .label {
  order: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.viewMenu__sectionItem .link .count,
.viewMenu__sectionItem .link .flex .count {
  order: 1;
  min-width: 2.5em;
  margin-right: 0.75em;
  margin-left: 0;
}
.viewMenu__sectionItem.is\:open .count {
  background-color: #d2dbf9;
  color: #355cdd;
}
.viewMenu__sectionItem.is\:active {
  font-weight: bold;
}
.viewMenu__sectionItem input {
  display: inline-block;
  height: auto;
  background-color: transparent;
  padding: 0;
  border: none;
  border-bottom: 1px solid;
  color: inherit;
  border-radius: 0;
}
.viewMenu__section .addItemButton {
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.6em;
  color: #355cdd;
}
.viewMenu__section .addItemButton:hover {
  color: #2e4cb1;
  background-color: #9cb3fc;
}
.viewMenu__sectionItem.is\:open:hover {
  background-color: transparent;
}
.viewMenu__sectionItem.is\:open > .viewMenu__sectionItemLabel,
.viewMenu__sectionItem.is\:open > .link {
  font-weight: 500;
  color: #fff;
  background-color: #4a6fe8;
}
.viewMenu__sectionItem.is\:open > .viewMenu__sectionItemLabel a,
.viewMenu__sectionItem.is\:open > .link a {
  color: #fff;
}
.viewMenu__section .addItemButton.is\:disabled {
  color: #abafb8;
  cursor: not-allowed;
}
.viewMenu__section .addItemButton.is\:disabled:hover {
  color: #355cdd;
}
.viewMenu__subMenu {
  background-color: transparent;
  border-left: 2px solid #4a6fe8;
  margin: 0.5em 0 0.5em 2em;
}
.viewMenu__subMenuItem {
  padding: 0.667em 1.25em;
}
.viewMenu__subMenuItem:hover {
  text-decoration: underline;
}
.viewMenu__subMenuItem.is\:open {
  font-weight: bold;
}
@media (min-width: 1024px) {
  .viewMenu .hide\:menu {
    overflow: hidden;
  }
  .hide\:menu .viewMenu__section {
    overflow: hidden;
  }
  .hide\:menu .viewMenu__sectionTitle {
    width: 5rem;
    padding: 0;
    justify-content: center;
  }
  .hide\:menu .viewMenu__sectionTitle i {
    display: block;
    visibility: visible;
  }
  .hide\:menu .viewMenu__sectionTitle .label {
    display: none;
  }
  .hide\:menu .viewMenu__sectionTitle::after {
    display: none;
  }
  .hide\:menu .viewMenu__sectionTitle .count {
    font-size: 0.8rem;
  }
  .hide\:menu .viewMenu__sectionItem a:link,
  .hide\:menu .viewMenu__sectionItem a:visited {
    padding: 0.75em 1em 0.75em 1em;
  }
  .hide\:menu .viewMenu__sectionItem .label {
    display: none;
  }
  .hide\:menu .viewMenu__subMenu {
    margin-left: 0.5em;
    counter-reset: submenu;
  }
  .hide\:menu .viewMenu__subMenuItem a:link,
  .hide\:menu .viewMenu__subMenuItem a:visited {
    padding-left: 0.75em;
  }
  .hide\:menu .viewMenu__subMenuItem a::before {
    content: counter(submenu, decimal-leading-zero);
    counter-increment: submenu;
  }
  .viewMenu__sectionItem .popoverTrigger {
    display: none;
  }
  .viewMenu__sectionItem .popoverTrigger.is\:active {
    display: inline-block;
  }
  .viewMenu__sectionItem:hover .popoverTrigger {
    display: inline-block;
  }
}
.viewMenu__user {
  font-size: 1.16666667rem;
  line-height: 1.42857143;
  background-color: #fff;
  padding: 1rem 1rem 1rem 0.5rem;
  display: grid;
  grid-template-columns: 4rem 1fr 3rem;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "icon user logout" "icon actions logout";
  position: relative;
}
.viewMenu__user a {
  text-decoration: none;
  color: #5f82f3;
  display: flex;
  align-items: center;
}
.viewMenu__user a:hover {
  color: #355cdd;
}
.viewMenu__user a:hover > span {
  text-decoration: underline;
}
.viewMenu__user::before {
  grid-area: icon;
  font-size: 3.33333333rem;
  line-height: 1;
  color: #809dfc;
  place-self: start center;
}
.viewMenu__user::after {
  content: '';
  background-image: linear-gradient(to top, rgba(37, 59, 133, 0.1) 0%, transparent 100%);
  position: absolute;
  left: 0;
  right: 0;
  top: -3em;
  height: 3em;
  pointer-events: none;
}
.viewMenu__userName {
  grid-area: user;
  font-weight: 500;
  color: #1c2c63;
  font-size: 1.25rem;
  line-height: 1.46666667;
}
.viewMenu__userActions {
  grid-area: actions;
}
.viewMenu__userActions a {
  display: inline;
}
.viewMenu__userActions a::before {
  margin-right: 0.25em;
}
.viewMenu__userLogout {
  grid-area: logout;
  place-self: center center;
}
.viewMenu__userLogout .button {
  display: flex;
  align-items: center;
  padding: 0.25em;
  border-radius: 0.45em;
  background-color: #e9eefc;
  transition: color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), border-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), background-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.viewMenu__userLogout .button::before {
  font-size: 1.5rem;
  line-height: 1;
  color: #5f82f3;
}
.viewMenu__userLogout .button:hover {
  background-color: #809dfc;
  color: #fff;
}
.viewMenu__userLogout .button:hover::before {
  color: #fff;
}
.viewMenu__content .spinner {
  top: 50%;
  position: relative;
  margin-top: -4rem;
}
.count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 2em;
  min-height: 2em;
  background-color: #fff;
  background-color: #355cdd;
  font-weight: bold;
  color: #fff;
  border-radius: 10%;
  font-size: 1rem;
  line-height: 1.66666667;
}
.viewSide__header {
  padding: 0 0.25em 1em;
}
@media (min-width: 768px) {
  .viewSide__header {
    margin-top: 2em;
    padding: 0 1em 1.25em;
  }
}
.viewSide__title {
  font-size: 1.5em;
  line-height: 1;
  font-weight: 700;
}
@media (min-width: 768px) {
  .viewSide__title {
    font-size: 1.75em;
  }
}
@media (min-width: 1366px) {
  .viewSide__title {
    font-size: 2em;
  }
}
.headlineList {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.headlineList__header {
  border-bottom: 1px solid #355cdd;
}
@media (min-width: 768px) {
  .headlineList__header {
    margin-top: 1.5em;
  }
}
.headlineList__headerTitle {
  font-size: 2rem;
  line-height: 1;
  font-weight: bold;
}
.headlineList__headerTitle.has\:icon::before {
  font-size: inherit;
  display: inline-block;
  width: 3.33333333rem;
}
@media (min-width: 768px) {
  .headlineList__headerTitle.has\:icon::before {
    width: 4.16666667rem;
  }
}
@media (min-width: 768px) {
  .headlineList__headerTitle {
    font-size: 2.66666667rem;
    line-height: 1;
  }
}
@media (min-width: 1366px) {
  .headlineList__headerTitle {
    font-size: 3.16666667rem;
    line-height: 1;
  }
}
.headlineList__headerIcon {
  display: inline-block;
  width: 3.33333333rem;
}
@media (min-width: 768px) {
  .headlineList__headerIcon {
    width: 5rem;
  }
}
.headlineList__headerFilters {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}
.headlineList__headerMeta {
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  font-size: 1.16666667rem;
  line-height: 1.57142857;
  padding: 0.5em 0 0.66em;
  flex-flow: row wrap;
}
@media (min-width: 768px) {
  .headlineList__headerMeta {
    font-size: 1.33333333rem;
    line-height: 1.375;
  }
}
.headlineList__header.has\:icon .headlineList__headerMeta {
  margin-left: 0.5rem;
}
@media (min-width: 768px) {
  .headlineList__header.has\:icon .headlineList__headerMeta {
    margin-left: 5.33333333rem;
  }
}
@media (min-width: 768px) {
  .headlineList__header.has\:icon .filterTagList {
    margin-left: 4.61538462em;
  }
}
.headlineList__info > *:not(:first-child) {
  padding-left: 0.75em;
  margin-left: 0.75em;
  border-left: 1px solid #9194a1;
}
.headlineList__controls {
  display: flex;
  visibility: visible;
  margin-left: auto;
}
.headlineList__controls .buttonGroup,
.headlineList__controls .button {
  display: none;
  visibility: hidden;
}
@media (min-width: 768px) {
  .headlineList__controls .buttonGroup,
  .headlineList__controls .button {
    display: flex;
    visibility: visible;
    margin-left: auto;
  }
}
.headlineList__controls > .buttonGroup,
.headlineList__controls > .button {
  margin-left: 0.33333333rem;
  margin-right: 0;
}
.headlineList__controls .switch {
  margin-right: 0.25em;
}
.headlineList__controls .switch__labelText {
  display: none;
}
@media (min-width: 768px) {
  .headlineList__controls .switch__labelText {
    display: inline;
  }
}
.headlineList__controls .button {
  height: 2.14285714em;
  width: 2.14285714em;
  justify-content: center;
  align-items: center;
  border-radius: 0.45em;
  background-color: rgba(204, 216, 252, 0.3);
  color: #5f82f3;
}
@media (min-width: 768px) {
  .headlineList__controls .button {
    display: flex;
  }
}
.headlineList__controls .button::before {
  color: #5f82f3;
}
.headlineList__controls .button:hover {
  background-color: rgba(204, 216, 252, 0.5);
}
.headlineList__controls .button.is\:active {
  color: #fff;
  background-color: #809dfc !important;
}
.headlineList__controls .button.is\:active::before {
  color: #fff;
}
.headlineList__item {
  position: relative;
  padding: 1.33333333rem 1rem 1.33333333rem 0rem;
}
@media (min-width: 768px) {
  .headlineList__item {
    padding: 1.75rem 1rem 1.75rem 0.5rem;
  }
}
@media (min-width: 1366px) {
  .headlineList__item {
    padding: 1.83333333rem 1rem 2rem 0.5rem;
  }
}
.headlineList__itemHeader {
  font-size: 0.83333333rem;
  line-height: 1.8;
  display: flex;
  align-items: flex-end;
}
@media (min-width: 768px) {
  .headlineList__itemHeader {
    align-items: center;
    font-size: 1rem;
    line-height: 1.66666667;
  }
}
.headlineList__itemReputation {
  display: flex;
  cursor: pointer;
}
.headlineList__itemReputation.is\:trusted .icon::before {
  color: #355cdd;
}
.headlineList__itemReputation.is\:suspect .icon::before {
  color: #b24334;
}
.headlineList__itemMeta {
  display: inline-flex;
  align-items: center;
  color: #757885;
}
.headlineList__itemMeta > * {
  padding: 0rem 0.66666667rem;
}
.headlineList__itemMeta > *:first-child {
  padding-left: 8;
}
.headlineList__itemMeta > *:not(:first-child) {
  border-left: 1px solid #9cb3fc;
}
.headlineList__itemAIResultBadge {
  color: #5f82f3;
  display: flex;
  align-items: center;
  border: 1px solid #5f82f3;
  border-radius: 0.75em;
  padding: 0 0.5rem;
}
.headlineList__itemAIResultBadge:before {
  margin-right: 0.25em;
  font-size: 1.2rem;
}
.headlineList__itemLang {
  background-color: transparent;
  color: inherit;
}
.headlineList__itemActions {
  position: absolute;
  top: 1.33333333rem;
  right: 0;
  display: flex;
  flex-flow: row nowrap;
  background: transparent !important;
  color: #355cdd;
  gap: 0.3em;
}
@media (min-width: 768px) {
  .headlineList__itemActions {
    right: 0.83333333rem;
  }
}
.headlineList__itemAction {
  background: transparent !important;
  color: #355cdd;
  padding: 0;
}
@media (min-width: 1366px) {
  .headlineList__itemAction.headlineList__itemDebugButton,
  .headlineList__itemAction.headlineList__itemShareButton:not(.is\:open) {
    visibility: hidden;
  }
}
.headlineList__itemDebugButton::before {
  font-size: 1.25rem;
  line-height: 1;
}
.headlineList__itemTitle {
  display: block;
  font-size: 1.33333333rem;
  line-height: 1.3125;
  margin-top: 0.5em;
  font-weight: 600;
  text-decoration: none;
}
.headlineList__itemTitle:hover,
.headlineList__itemTitle:visited:hover {
  text-decoration: underline;
  color: #355cdd;
}
.headlineList__itemTitle b {
  background-color: #9cb3fc;
}
@media (min-width: 768px) {
  .headlineList__itemTitle {
    font-size: 1.41666667rem;
    line-height: 1.58823529;
  }
}
.headlineList__itemDesc,
.headlineList__itemLongtext {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 0.5em;
}
.headlineList__itemDesc b,
.headlineList__itemLongtext b {
  background-color: #9cb3fc;
}
.headlineList__itemDesc span,
.headlineList__itemLongtext span {
  padding-right: 0.33333333rem;
}
.headlineList__itemLongtext {
  white-space: pre-line;
  border-top: 1px solid #253b85;
  padding-top: 0.5em;
}
.headlineList__itemSummaryBtn {
  padding: 0;
  transition: opacity 0.2s;
  color: #355cdd;
  display: inline;
  line-height: 175%;
}
.headlineList__itemSummaryBtnLabel {
  display: inline-block;
  transition: all 0.2s;
  font-size: 1em;
}
@media (min-width: 1366px) {
  .headlineList__itemSummaryBtnLabel {
    transform: translateX(0.6em);
    opacity: 0;
  }
}
.headlineList__itemSummaryBtnIcon {
  display: inline-block;
  opacity: 1;
  padding: 0;
  font-size: 1em;
  margin-left: 0.1em;
  transform: translateY(0.1em);
  transition: all 0.2s;
}
.headlineList__itemSummaryBtnIcon::before {
  padding: 0;
  font-size: 1em;
}
@media (min-width: 768px) {
  .is\:compact .headlineList__item {
    padding: 1rem 0rem 1rem;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-areas: "title lang repuation source sentiment actions";
    grid-template-columns: 1fr auto auto min-content auto 2.5em;
    place-items: start end;
    grid-column-gap: 0.25em;
  }
  .is\:compact .headlineList__item.show\:sentiment {
    grid-template-areas: "lang title source sentiment date bookmark";
    grid-template-columns: min-content 4fr 1fr 2em 1.5em 1.5em;
  }
  .is\:compact .headlineList__itemDesc,
  .is\:compact .headlineList__itemLongtext {
    display: none;
    visibility: hidden;
  }
  .is\:compact .headlineList__itemSentiment {
    padding: 0;
    padding: 0rem 0.33333333rem;
    grid-area: sentiment;
    place-self: start center;
    color: #355cdd;
  }
  .is\:compact .headlineList__itemReputation {
    padding: 0;
    grid-area: repuation;
    place-self: start center;
    color: #355cdd;
  }
  .is\:compact .headlineList__itemHeader,
  .is\:compact .headlineList__itemMeta {
    display: contents;
  }
  .is\:compact .headlineList__itemHeader > *,
  .is\:compact .headlineList__itemMeta > * {
    border: none !important;
  }
  .is\:compact .headlineList__itemActions {
    position: static;
    grid-area: actions;
    width: 100%;
    justify-content: center;
  }
  .is\:compact:hover .headlineList__itemDebugButton {
    display: none !important;
  }
  .is\:compact .headlineList__itemShareButton {
    display: flex;
  }
  .is\:compact .headlineList__itemTitle {
    grid-area: title;
    place-self: center start;
    margin-top: 0;
    font-size: 1.16666667rem;
    line-height: 1.28571429;
    font-weight: 500;
  }
  .is\:compact .headlineList__itemPublished {
    padding: 0;
    grid-area: date;
    white-space: nowrap;
  }
  .is\:compact .headlineList__itemFavButton {
    padding-left: 0;
    position: static;
    grid-area: bookmark;
  }
  .is\:compact .headlineList__itemLang {
    padding: 0;
    grid-area: lang;
    padding-right: 0.33333333rem;
    display: flex;
    text-decoration: none;
  }
  .is\:compact .headlineList__itemLang b {
    display: none;
  }
  .is\:compact .headlineList__itemSource {
    grid-area: source;
    place-self: start end;
    flex-flow: row-reverse nowrap;
  }
  .is\:compact .headlineList__itemSource .sourceBadge__published {
    padding: 0 0.667em 0 0.45em;
    border-top-right-radius: 0.75em;
    border-bottom-right-radius: 0.75em;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .is\:compact .headlineList__itemSource .sourceBadge__host {
    padding: 0 0.667em 0 1em;
    border-top-left-radius: 0.75em;
    border-bottom-left-radius: 0.75em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.headlineList__item:hover .headlineList__itemAction.headlineList__itemDebugButton,
.headlineList__item:hover .headlineList__itemAction.headlineList__itemShareButton {
  visibility: visible;
}
.headlineList__item:hover .headlineList__itemSummaryBtnLabel {
  opacity: 1;
  transform: translateX(0.1em);
}
.headlineList__item:hover .headlineList__itemSummaryBtnIcon {
  transform-origin: center center;
  transform: translateY(0.1em) scale(1.15);
}
@media (min-width: 768px) {
  .headlineList.has\:customStreams .headlineList__headerTitle {
    margin-right: 16.66666667rem;
  }
}
.headlineList .list {
  flex: 1;
}
.headlineList .list__item + .list__item {
  border-top: 1px solid #9cb3fc;
}
.headlineList > .spinner {
  top: -4rem;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}
.headlineList.has\:more .list__footer {
  height: 10.66666667rem;
  display: flex;
}
.overrideStreamTabs {
  display: flex;
  align-items: center;
}
.overrideStreamTabs__label {
  font-weight: bold;
  font-size: 1.66666667rem;
  line-height: 1;
  margin: 0rem 0.83333333rem 0rem 0.33333333rem;
  display: none;
}
.overrideStreamTabs__items {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
}
.overrideStreamTabs__item {
  flex: 0 1 auto;
  max-width: 50%;
  width: 100%;
  background-color: rgba(204, 216, 252, 0.3);
  color: #5f82f3;
  margin-left: 0 !important;
}
.overrideStreamTabs__itemPrefix {
  margin-right: 0.5em;
}
.overrideStreamTabs__itemCount {
  position: absolute;
  top: -1em;
  font-size: 0.75em;
  padding: 0.1em 0.55em;
  border-radius: 1em;
  color: #fc5a0f;
  border: 1px solid #fc5a0f;
  background: white;
}
.overrideStreamTabs__itemLabel {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.overrideStreamTabs__item::before {
  font-size: 1.15em;
}
.overrideStreamTabs__item:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.overrideStreamTabs__item:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.overrideStreamTabs .button.is\:active {
  color: #fff;
  background-color: #809dfc !important;
}
.overrideStreamTabs .button.is\:active::before {
  color: #fff;
}
@media (min-width: 768px) {
  .overrideStreamTabs__items {
    width: auto;
  }
  .overrideStreamTabs__item {
    flex: 0 1 0;
    max-width: 20rem;
  }
  .overrideStreamTabs__label {
    display: block;
  }
}
.noResults {
  margin: auto;
  padding-bottom: 4rem;
  text-align: center;
}
.noResults__title {
  font-size: 1.75rem;
  line-height: 1.33333333;
  margin-bottom: 0.66666667rem;
  font-weight: 600;
}
.noResults__subtitle {
  font-size: 1.5rem;
  line-height: 1.33333333;
  margin-top: 0.5rem;
}
.noResults__actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 2.83333333rem;
}
.noResults__action {
  margin: 0.66666667rem 0.66666667rem 0rem 0.66666667rem;
}
.widget {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0.1em 0.25em rgba(37, 59, 133, 0.05), 0 0.25em 0.45em rgba(37, 59, 133, 0.05);
  border-radius: 1.5em;
}
.widget + .widget {
  margin-top: 1.5em;
}
.widget__header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 1em 1.5em 0;
  background-color: rgba(255, 255, 255, 0.8);
  border-top-right-radius: 1.5em;
  border-top-left-radius: 1.5em;
}
@media (min-width: 768px) {
  .widget__header {
    flex-flow: row nowrap;
  }
}
.widget__body {
  display: flex;
  flex-flow: column;
  position: relative;
  padding: 0.75em 1.5em 1.5em;
  background-color: #fff;
  border-bottom-right-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
}
.widget__spinner {
  align-self: center;
}
.widget__notice {
  margin: auto;
  padding: 1.33333333rem 0rem;
  font-size: 1.5rem;
  line-height: 1.33333333;
}
.widget__title {
  font-size: 1.33333333rem;
  line-height: 1.5;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.widget__subtitle {
  font-size: 1rem;
  line-height: 1.5;
  color: #355cdd;
  font-weight: bold;
  white-space: nowrap;
  display: flex;
  max-width: 20rem;
}
.widget__subtitleClamped {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget .widget__controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
.widget .widget__controls .select {
  max-width: 10em;
}
.widget__vis {
  display: flex;
  width: 100%;
  position: relative;
  margin: auto;
}
.widget__vis--pack {
  height: 30rem;
}
.widget__vis--pack circle {
  cursor: pointer;
}
.widget--tabbed {
  padding: 0;
  background-color: transparent;
}
.widget .tabs__controls {
  display: flex;
}
.widget .tabs__control {
  font-size: 1.16666667rem;
  line-height: 1.42857143;
  padding: 0.75em 1em;
  margin-right: 0.1em;
  font-weight: bold;
  color: #355cdd;
  background-color: #b4c5fc;
  transition: background-color 0.3s ease-out;
  cursor: pointer;
}
.widget .tabs__control.is\:active {
  color: #1c2c63;
  background-color: #fff;
  cursor: default;
}
.widget .tabs__control:not(.is\:active):hover {
  background-color: #9cb3fc;
}
.widget .tabs__control ild {
  border-top-left-radius: 1.5em;
}
.widget .tabs__control:last-child {
  border-top-right-radius: 1.5em;
}
.widget.has\:error .widget__body {
  justify-content: center;
}
.widget.has\:error .widget__vis {
  display: flex;
  justify-content: center;
}
.widget.is\:loading .widget__body {
  justify-content: center;
}
.widget.is\:loading .widget__vis {
  display: flex;
  justify-content: center;
}
.widget.is\:loading .widget__vis > *:not(.widget__spinner) {
  display: none;
}
.widget .statBoard {
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  z-index: 10;
  margin: 0.625em auto -1.5em;
}
@media (min-width: 768px) {
  .widget .statBoard {
    margin: 0;
  }
}
.widget .statBoard > * {
  font-size: 0.91666667rem;
  line-height: 1.45454545;
  text-align: center;
  padding: 0.25em 0.5em;
}
.widget .statBoard > *:not(:first-child) {
  border-left: 1px solid #eaeaed;
}
.widget .statBoard__itemCount {
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 500;
  color: #355cdd;
}
.widget .statBoard__figure {
  min-width: 4.25em;
  cursor: pointer;
  transition: color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), border-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1), background-color 0.18s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.widget .statBoard__figure::after {
  font-family: "headlinehunter-icons";
  display: block;
  font-size: 2em;
  line-height: 1;
}
.widget .statBoard__figure.sentiment\:none {
  color: #757885;
}
.widget .statBoard__figure.sentiment\:none::after {
  content: "\e903";
}
.widget .statBoard__figure.sentiment\:positive {
  color: #58db9f;
}
.widget .statBoard__figure.sentiment\:positive::after {
  content: "\e918";
}
.widget .statBoard__figure.sentiment\:positive:hover {
  background-color: #d7fceb;
  color: #49af80;
}
.widget .statBoard__figure.sentiment\:positive.is\:active {
  background-color: #58db9f;
  color: rgba(255, 255, 255, 0.8);
}
.widget .statBoard__figure.sentiment\:neutral {
  color: #ebb319;
}
.widget .statBoard__figure.sentiment\:neutral::after {
  content: "\e916";
}
.widget .statBoard__figure.sentiment\:neutral:hover {
  background-color: #fcf4dc;
  color: #c2a962;
}
.widget .statBoard__figure.sentiment\:neutral.is\:active {
  background-color: #F3D277;
  color: rgba(255, 255, 255, 0.8);
}
.widget .statBoard__figure.sentiment\:negative {
  color: #f28779;
}
.widget .statBoard__figure.sentiment\:negative::after {
  content: "\e917";
}
.widget .statBoard__figure.sentiment\:negative:hover {
  background-color: #fce1dd;
  color: #c26e63;
}
.widget .statBoard__figure.sentiment\:negative.is\:active {
  background-color: #f28779;
  color: rgba(255, 255, 255, 0.8);
}
.widget .statRange {
  display: flex;
  align-items: center;
}
.widget .statRange__figure {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 0.33333333rem;
}
.widget .statRange__figure > span {
  padding: 0 0.5em;
  font-size: 1.16666667rem;
  line-height: 1.42857143;
}
.widget .statRange__figure::before {
  font-family: "headlinehunter-icons";
  font-size: 2rem;
  line-height: 1;
}
.widget .statRange__figure.sentiment\:positive {
  color: #58db9f;
  flex-flow: row-reverse;
}
.widget .statRange__figure.sentiment\:positive::before {
  content: "\e918";
}
.widget .statRange__figure.sentiment\:positive:hover {
  background-color: #d7fceb;
  color: #49af80;
}
.widget .statRange__figure.sentiment\:neutral {
  color: #ebb319;
}
.widget .statRange__figure.sentiment\:neutral::before {
  content: "\e916";
}
.widget .statRange__figure.sentiment\:neutral:hover {
  background-color: #fcf4dc;
  color: #c2a962;
}
.widget .statRange__figure.sentiment\:negative {
  color: #f28779;
}
.widget .statRange__figure.sentiment\:negative span {
  width: 2.75em;
}
.widget .statRange__figure.sentiment\:negative::before {
  content: "\e917";
}
.widget .statRange__figure.sentiment\:negative:hover {
  background-color: #fce1dd;
  color: #c26e63;
}
.widget--pieChart {
  position: relative;
}
.widget--pieChart .widget__body {
  padding: 0;
}
.widget--pieChart .widget__vis {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  padding: 0.75em 1.333em 1.333em;
  position: static;
}
.widget--pieChart .widget__vis .pieChart {
  flex-basis: 8.75em;
}
.widget--pieChart .widget__vis .pieChart > svg {
  width: 100% !important;
  height: 100% !important;
  max-width: 100% !important;
}
.widget--pieChart .widget__vis .pieChart__labelCols {
  gap: 1em;
  display: flex;
  flex: 1 0 auto;
  flex-flow: row wrap;
  justify-content: space-evenly;
  max-width: 100%;
}
.widget--pieChart .widget__vis .pieChart__labels {
  padding: 0.5em 0;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  font-size: 1.33333333rem;
  line-height: 1.75;
}
.widget--pieChart .widget__vis .pieChart__labelBullet {
  display: inline-block;
  margin-right: 0.5rem;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.widget--pieChart .widget__vis .pieChart__vis {
  position: absolute;
  right: 0.66em;
  bottom: 0.66em;
}
.widget--pieChart .widget__vis .pieChart__labels,
.widget--pieChart .widget__vis .pieChart__vis {
  flex-grow: 1;
}
.widget--pieChart .widget__vis .pieChart__label {
  font-size: 0.875em;
  line-height: 1.5;
  padding: 0.25em 0;
  white-space: nowrap;
}
.widget--pieChart .widget__vis .pieChart__label.is\:focus {
  cursor: pointer;
  text-decoration: underline;
}
.widget--languageDistribution .pieChart__labels {
  font-size: 1.16666667rem;
  line-height: 1.85714286;
}
.widget--languageDistribution .widget__vis {
  min-height: 13.33333333rem;
}
.widget--cryptoForecast .widget__body {
  flex-flow: row wrap;
}
.widget--opinionBarStack .widget__vis {
  height: 18.33333333rem;
}
.widget--opinionLine .widget__vis {
  height: 18.33333333rem;
}
.widget--cryptoLine .widget__vis {
  height: 18.33333333rem;
}
.widget--countryDistribution .widget__body {
  padding: 0.75em 0 0;
}
.widget--countryDistribution .widget__vis {
  height: 25rem;
}
.widget--countryDistribution .widget__mapControls {
  position: absolute;
  bottom: 0.75em;
  right: 1.125em;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.16666667rem 0rem 0.25rem 0rem;
  border-radius: 0.5em;
}
.widget--countryDistribution .widget__mapControls button {
  background-color: transparent !important;
  color: #5f82f3;
}
.widget--countryDistribution .widget__mapControls button:hover {
  color: #355cdd;
}
.betaLabel {
  margin-top: -0.66em;
  margin-left: 0.33em;
  color: #595d69;
  font-size: 1rem;
  line-height: 1.33333333;
}
.worldMap {
  border-bottom-right-radius: 1.5em;
  border-bottom-left-radius: 1.5em;
}
.worldMap.is\:dragging {
  cursor: move;
}
.widget--topics .widget__body {
  min-height: 17.91666667rem;
}
.widget--topics .buttonGroup {
  font-size: 1.1rem;
}
.widget--topics .select--naked .select__value {
  padding-right: 0;
}
.widget--topics .icon {
  margin-right: 0.3em;
}
.widget--topics .button {
  border-radius: 0.45em;
  background-color: #e9eefc;
  color: #2e4cb1;
}
.widget--topics .button.is\:active {
  background-color: #5f82f3;
  color: #fff;
}
.widget--topics .button.is\:active::before,
.widget--topics .button.is\:active b {
  color: #fff;
}
.widget--topics .button:not(.is\:active):hover {
  background-color: #809dfc;
  color: #fff;
}
.widget--topics .button:not(.is\:active):hover::before {
  color: #fff;
}
.widget--topics .showMore {
  display: flex;
  flex-flow: column nowrap;
  line-height: 1;
  margin-top: 0.75em;
  color: #5f82f3;
}
.widget--topics .showMore .icon,
.widget--topics .showMore .icon:before {
  line-height: 0.75;
}
.spinner {
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
  pointer-events: none;
}
.spinner:before {
  position: absolute;
  height: 3.5rem;
  width: 3.5rem;
  content: '';
  color: white;
  background: transparent;
  border-radius: 50%;
  border: 0 solid transparent;
  border-width: 1pt;
  animation: spinnerDefault 4.2s infinite linear;
}
.spinner:after {
  position: absolute;
  height: 2.33rem;
  width: 2.33rem;
  content: '';
  color: white;
  background: transparent;
  border-radius: 50%;
  border: 0 solid transparent;
  border-width: 1pt;
  animation: spinnerDefault 2.4s infinite linear;
}
@keyframes spinnerDefault {
  25% {
    transform: rotatez(180deg);
    border-color: transparent #1c2c63 transparent #9cb3fc;
  }
  50% {
    transform: rotatez(270deg);
    border-color: transparent #5f82f3 transparent #2e4cb1;
  }
  100% {
    transform: rotatez(360deg);
  }
}
@keyframes spinnerDefaultInverted {
  25% {
    transform: rotatez(180deg);
    border-color: transparent #2e4cb1 transparent #ccd8fc;
  }
  50% {
    transform: rotatez(270deg);
    border-color: transparent #9cb3fc transparent #5f82f3;
  }
  100% {
    transform: rotatez(360deg);
  }
}
.spinner.spinner--inverted:before {
  animation: spinnerDefaultInverted 4.2s infinite linear;
}
.spinner.spinner--inverted:after {
  animation: spinnerDefaultInverted 2.4s infinite linear;
}
.spinner--large {
  height: 12rem;
  width: 12rem;
  animation: pulse 4.2s infinite ease-in-out;
}
.spinner--large:before {
  height: 8rem;
  width: 8rem;
  border-width: 1pt;
  animation: spinnerLarge 4.2s infinite linear;
}
.spinner--large:after {
  position: absolute;
  height: 6rem;
  width: 6rem;
  border-width: 1pt;
  animation: spinnerLarge 2.4s infinite linear;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinnerLarge {
  25% {
    transform: rotatez(180deg);
    border-color: transparent #5f82f3 transparent #2e4cb1;
  }
  50% {
    transform: rotatez(270deg);
    border-color: transparent #2e4cb1 transparent #5f82f3;
  }
  100% {
    transform: rotatez(360deg);
  }
}
.spinner--inline {
  display: inline-flex;
  top: 0.2rem;
}
.spinner--small {
  position: relative;
  height: 1.4rem;
  width: 1.4rem;
}
.spinner--small:before {
  height: 0.95rem;
  width: 0.95rem;
  border-width: 0.15rem;
  animation: spinnerSmallInverted 2s infinite linear;
}
.spinner--small:after {
  height: 0.95rem;
  width: 0.95rem;
  border-width: 0.15rem;
  animation: spinnerSmallInverted 4s infinite linear;
}
@keyframes spinnerSmall {
  25% {
    transform: rotatez(180deg);
    border-color: transparent #253b85 transparent #253b85;
  }
  50% {
    transform: rotatez(270deg);
    border-color: transparent #809dfc transparent #809dfc;
  }
  100% {
    transform: rotatez(360deg);
  }
}
@keyframes spinnerSmallInverted {
  25% {
    transform: rotatez(180deg);
    border-color: transparent #ccd8fc transparent #ccd8fc;
  }
  50% {
    transform: rotatez(270deg);
    border-color: transparent #809dfc transparent #809dfc;
  }
  100% {
    transform: rotatez(360deg);
  }
}
.spinner--small.spinner--inverted:before {
  animation: spinnerSmallInverted 2s infinite linear;
}
.spinner--small.spinner--inverted:after {
  animation: spinnerSmallInverted 4s infinite linear;
}
.contextMenu {
  padding: 0.83333333rem 1.33333333rem 1.33333333rem 1.33333333rem;
  background-color: #fff;
  border-radius: 0.8em;
  box-shadow: 0 0 1em rgba(37, 59, 133, 0.07), 0 0.25em 0.25em rgba(17, 29, 66, 0.15), 0 0.75em 0.75em rgba(28, 44, 99, 0.1);
  max-width: 22rem;
}
.contextMenu__title {
  font-size: 1.16666667rem;
  line-height: 1.71428571;
  padding: 0.5em 0.625em;
  margin-bottom: 0.625em;
  font-weight: bold;
  color: #1c2c63;
  border-bottom: 1px solid #5f82f3;
}
.contextMenu__item {
  color: #1c2c63;
  border-radius: 0.45em;
  background: none;
  height: 100%;
  width: 100%;
  margin: 0;
  display: block;
  text-align: left;
  font-size: 1.33333333rem;
  line-height: 1.5;
  padding: 0.83333333rem 1.33333333rem 0.83333333rem 1rem;
}
.contextMenu__item::before,
.contextMenu__item b {
  margin-right: 0.5em;
  color: #809dfc;
}
.contextMenu__item::before {
  top: 2px;
  position: relative;
}
.contextMenu__item:focus-visible {
  background-color: inherit;
  color: inherit;
  outline: 0.0625em solid #ccd8fc;
}
.contextMenu__item:focus-visible::before,
.contextMenu__item:focus-visible b {
  color: #5f82f3;
}
.contextMenu__item:hover {
  background-color: #5f82f3;
  color: #fff;
}
.contextMenu__item:hover .betaLabel {
  color: #fff;
}
.contextMenu__item:hover::before,
.contextMenu__item:hover b {
  color: #ccd8fc;
}
.contextMenu .bookmarkListSelection li:not(:last-child) {
  margin-bottom: 0.33em;
}
.contextMenu .checkbox {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.contextMenu--condensed {
  max-width: 13.75rem;
  border-radius: 0.45em;
  padding: 0;
  margin: 0rem 0rem 0rem 0rem;
  height: auto;
  overflow: hidden;
}
.contextMenu--condensed .contextMenu__item {
  padding: 0.83333333rem 1.66666667rem 1rem 1rem;
  border-radius: 0;
  font-size: 1.16666667rem;
  line-height: 1.14285714;
}
.contextMenu--condensed .contextMenu__item:focus-visible {
  outline: none;
}
.contextMenu--condensed .contextMenu__item:hover {
  background-color: #5f82f3;
  color: #fff;
}
.tooltip {
  z-index: 400;
  position: absolute;
  padding: 1em 1.5em;
  background-color: #fff;
  color: #1c2c63;
  box-shadow: 0em 0em 0.2em rgba(17, 29, 66, 0.1), 0em 0.5em 0.25em rgba(37, 59, 133, 0.1);
  border-radius: 0.5em;
}
.tooltip__head {
  display: flex;
}
.tooltip__title {
  font-size: 1.33333333rem;
  line-height: 1.5;
  font-size: 1em;
  padding: 0.5em 0.625em;
  padding: 0.66666667rem 0.83333333rem;
  margin-bottom: 0.66666667rem;
  font-weight: bold;
  border-bottom: 1px solid #5f82f3;
  white-space: no-wrap;
}
.tooltip__data {
  padding: 0.66666667rem 0.83333333rem;
  font-size: 1.16666667rem;
  line-height: 1.71428571;
}
.tooltip__data dt {
  font-weight: bold;
  padding-right: 0.66666667rem;
}
.tooltip__data dd {
  margin-bottom: 0.33333333rem;
}
.tooltip--translation {
  width: 40rem;
}
.tooltip--translation .tooltip__head > * + * {
  margin-left: 0.25rem;
}
.hint {
  margin-left: -8px;
  padding: 0.5em 1em;
  max-width: 40rem;
  font-size: 0.875em;
}
.popoverContent {
  max-width: var(--radix-popover-content-available-width);
  overflow: auto;
}
.popoverContent.hint {
  z-index: 400;
  padding: 1em 1.5em;
  background-color: #fff;
  color: #1c2c63;
  box-shadow: 0em 0em 0.2em rgba(17, 29, 66, 0.1), 0em 0.5em 0.25em rgba(37, 59, 133, 0.1);
  border-radius: 0.5em;
  width: var(--radix-popover-content-available-width);
  max-width: 40rem;
  min-width: 25rem;
}
.hintTrigger {
  padding: 0;
  color: #355cdd;
  background-color: transparent;
  margin-left: 0.3em;
}
.datePickerDialog {
  overflow-y: auto;
  padding: 1em;
}
.datePickerDialog .dialog__title {
  padding-left: 0.25em;
}
.datePickerDialog .dialog__close {
  display: block;
  background-color: rgba(171, 175, 184, 0.2);
  color: #3245B9;
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: 1em;
  right: 1em;
  border-radius: 50%;
  transition: all 0.3s;
  font-weight: 900;
}
.datePickerDialog .dialog__close:focus {
  outline: none;
  background-color: rgba(171, 175, 184, 0.4);
}
.datePickerDialog .dialog__close::before {
  font-size: 1.5em;
  font-weight: 900;
}
.datePickerDialog .dialog__close:hover {
  color: #355cdd;
  background-color: rgba(171, 175, 184, 0.4);
}
.datePicker {
  display: inline-block;
  font-size: 1em;
  width: 100%;
  position: relative;
  border-color: #355cdd;
}
.datePicker,
.datePicker__body,
.datePicker__input {
  border-radius: 0.5em;
}
.datePicker__body {
  display: flex;
  border-radius: 0.5em;
  min-height: 2.625em;
}
.datePicker__body:focus,
.datePicker__body:focus-within {
  border-color: #2e4cb1;
}
.datePicker__label {
  display: none;
}
@media (min-width: 1024px) {
  .datePicker__label {
    display: block;
    padding-right: 1em;
  }
}
.datePicker__input {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  line-height: 1;
  margin: 1.5em 0 1.25em;
  font-size: 1.16666667rem;
  line-height: 1.28571429;
}
@media (min-width: 768px) {
  .datePicker__input {
    font-size: 1.5rem;
    line-height: 1.33333333;
  }
}
@media (min-width: 1024px) {
  .datePicker__input {
    height: 2.625em;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: baseline;
    margin: 0;
  }
}
.datePicker__inputWrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}
.datePicker__inputDateSegments {
  display: flex;
  flex-flow: row nowrap;
}
.datePicker__inputDate {
  display: flex;
  flex-flow: row nowrap;
}
.datePicker__inputSeparator {
  width: 2rem;
  text-align: center;
}
.datePicker .segment {
  padding: 0;
  font-variant-numeric: tabular-nums;
  text-align: end;
  align-self: baseline;
}
.datePicker .segment[aria-hidden="true"] {
  position: relative;
  left: -0.5em;
  pointer-events: none;
}
.datePicker .segment[role="spinbutton"] {
  min-width: 2rem;
  padding: 0.5rem 0.83333333rem;
  border-radius: 0.25em;
  background-color: #f7f8fd;
}
.datePicker .segment[role="spinbutton"]:hover {
  background-color: #e6eaf8;
}
.datePicker .segment[role="spinbutton"]:focus {
  color: #fff;
  background-color: #5f82f3;
}
.datePicker .segment[role="spinbutton"]:focus + .datePicker .segment[aria-hidden="true"] {
  color: #fff;
}
.datePicker .segment[role="spinbutton"]:focus + .segment[aria-hidden="true"] {
  color: #fff;
}
.datePicker__placeholder {
  width: 100%;
  color: #abafb8;
  height: 2.625em;
  line-height: 1;
  padding: 0.5em 0.75em;
}
.datePicker__toggle {
  background: transparent !important;
  width: 2.66666667rem;
  height: 100%;
  color: #5f82f3;
  border: none;
}
.datePicker__toggle:focus {
  outline: none;
}
.calendar {
  width: auto;
  border-radius: 0.5em;
}
.calendar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0rem;
}
.calendar__body {
  display: flex;
  gap: 1em;
}
.calendar__table {
  width: 100%;
}
.calendar__currentMonth {
  font-weight: bold;
}
.calendar__navButton {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  color: #355cdd;
  font-weight: bold;
  border-radius: 0.25em;
}
.calendar__navButton:hover {
  background-color: transparent;
}
.calendar__navButton.is\:disabled {
  color: #abafb8;
  cursor: default;
}
.calendar__columnLabels,
.calendar__days {
  display: grid;
  grid-template-columns: repeat(7, 14%);
  grid-auto-rows: 2.66666667rem;
  text-align: center;
}
@media (min-width: 1024px) {
  .calendar__columnLabels,
  .calendar__days {
    grid-template-columns: repeat(7, 3rem);
  }
}
.calendar__columnLabels > * {
  font-size: 1.16666667rem;
  line-height: 2.14285714;
  text-transform: uppercase;
  color: #abafb8;
}
.calendar__days {
  border-top: 0.08333333rem solid #e1e5f2;
}
.calendar__day {
  height: 100%;
  font-size: 1.33333333rem;
  line-height: 1.875;
  cursor: pointer;
}
.calendar__day:nth-child(7n) {
  border-right: none;
}
.calendar__day.is\:disabled {
  color: #abafb8;
  cursor: default;
}
.calendar__day.is\:selected {
  color: #fff;
  background-color: #809dfc;
  cursor: default;
}
.calendar__day:hover {
  background-color: #5f82f3;
  color: #fff;
}
.calendar__day.is\:focus {
  background-color: #5f82f3;
  color: #fff;
}
.calendar__day--otherMonth {
  background-color: #f8fafe;
}
.calendar__day.is\:disabled,
.calendar__day .is\:disabled:hover {
  background-color: #f8fafe;
  color: #abafb8;
}
.select {
  display: inline-block;
  position: relative;
  font-size: 1em;
  border: 0.08333333rem solid #5f82f3;
  height: 2.625em;
}
.select,
.select__body,
.select__value {
  border-radius: 0.5em;
}
.select__body {
  display: flex;
  align-items: center;
  height: 2.45833333em;
  background-color: #fff;
  cursor: pointer;
}
.select__value {
  width: 100%;
  inset-inline-end: 4rem;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  align-items: center;
  padding: 0.5em 0.75em;
  height: 2.45833333em;
  pointer-events: none;
}
.select__search::placeholder,
.select__placeholder {
  opacity: 1;
  color: #abafb8;
}
.select--naked {
  border: none;
}
.select--naked .select__body,
.select--naked .select__value {
  justify-content: end;
}
.select--naked .select__value {
  padding-right: 0.25em;
  color: #5f82f3;
  text-align: right;
}
.select__search {
  background: transparent !important;
  border: none;
  color: inherit;
  width: 100%;
  height: auto;
  max-height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}
.select__search:focus {
  outline: none;
}
.select__toggle,
.select__clear {
  background: transparent !important;
  border: none !important;
  height: 100%;
  width: 2em;
  color: #5f82f3;
}
.select__toggle:hover,
.select__clear:hover {
  color: #355cdd;
}
.select__body:focus,
.select__toggle:focus,
.select__clear:focus {
  outline: none;
}
.select:focus-visible,
.select:focus,
.select:has(:focus-visible),
.select:has(:focus) {
  border-color: #355cdd;
}
.select--checkList .select__value {
  pointer-events: none;
  position: absolute;
  left: 0;
  width: auto;
}
.select--checkList.has\:value .select__search {
  opacity: 0;
}
.select--checkList:focus-within .select__search {
  opacity: 1;
}
.select--checkList:focus-visible .select__value,
.select--checkList:has(:focus-visible) .select__value {
  display: none;
}
.select--small {
  height: 2.5rem;
}
.select--small .select__body {
  height: 2.33333333rem;
}
.select--small .select__value,
.select--small .select__search {
  font-size: 1.16666667rem;
  line-height: 1.14285714;
}
.selectPopover {
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 0.25em;
  box-shadow: 0em 0.5em 0.25em rgba(37, 59, 133, 0.1);
  overflow-y: auto;
  width: auto;
  pointer-events: auto;
  color: #1c2c63;
}
.selectPopover__label,
.selectPopover__sectionTitle {
  font-weight: bold;
  color: #809dfc;
  padding: 0.66666667rem 0.83333333rem 0.5rem;
}
.selectPopover__item {
  padding: 0.33333333rem 0.83333333rem;
  cursor: pointer;
  display: flex;
}
.selectPopover__item .icon {
  margin-right: 0.5em;
}
.selectPopover__item.is\:focus,
.selectPopover__item:focus {
  color: #1c2c63;
  background-color: #f4f7fe;
}
.selectPopover__item:hover {
  color: #fff;
  background-color: #5f82f3;
}
.selectPopover__item:hover .icon {
  color: #fff;
}
.selectPopover .checkbox__check {
  background-color: #fff;
}
.selectPopover .icon {
  color: #5f82f3;
}
.selectPopover--small {
  font-size: 1.16666667rem;
  line-height: 1.14285714;
}
.checkbox {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
}
.checkbox__check {
  font-size: 1.5rem;
  line-height: 0.88888889;
  border-color: #2e4cb1;
  border-style: solid;
  border-radius: 0;
  border-width: 0.08333333rem;
  height: 1em;
  width: 1em;
  min-height: 1em;
  min-width: 1em;
  display: inline-flex;
  justify-content: center;
  margin-right: 1rem;
}
.checkbox__label {
  font-weight: bold;
  color: #757a85;
}
.checkbox.is\:selected .checkbox__check,
.checkbox.is\:indeterminate .checkbox__check {
  background-color: #355cdd;
  color: #fff;
  border-color: #2e4cb1;
}
.checkbox.is\:selected .checkbox__label {
  color: #1c2c63;
}
.checkbox--small .checkbox__check {
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  font-size: 1.33333333rem;
  line-height: 0.875;
  margin-right: 1rem;
}
.topicLabels {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
}
.topicLabel {
  font-size: 1.16666667rem;
  line-height: 1;
  margin-top: 0.5em;
  margin-right: 0.5em;
  border-radius: 0.45em;
  display: inline-flex;
  flex-flow: row nowrap;
  max-width: 100%;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background-color: #ccd8fc;
}
.topicLabel > * {
  color: #1c2c63;
  text-decoration: none;
  display: flex;
  flex-flow: row nowrap;
  max-width: 100%;
}
.topicLabel > * .topicLabel__rank {
  flex-shrink: 0;
}
.topicLabel > * .topicLabel__label {
  overflow: hidden;
  text-overflow: ellipsis;
}
.topicLabel__label,
.topicLabel__count,
.topicLabel__rank {
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  white-space: nowrap;
  height: 2em;
}
.topicLabel__label {
  padding-left: 0.5em;
  padding-right: 0.75em;
  text-transform: capitalize;
}
.topicLabel__count,
.topicLabel__rank {
  font-weight: bold;
  padding-left: 0.75em;
  padding-right: 0.5em;
}
.topicLabel__count {
  border-top-right-radius: 0.45em;
  border-bottom-right-radius: 0.45em;
}
.topicLabel__rank {
  border-top-left-radius: 0.45em;
  border-bottom-left-radius: 0.45em;
}
.topicLabel:hover {
  background-color: #9cb3fc;
}
.topicLabel__count,
.topicLabel__rank {
  background-color: #9cb3fc;
  color: #355cdd;
}
.topicLabel.sentiment\:positive {
  background-color: #b1fcda;
}
.topicLabel.sentiment\:positive:hover {
  background-color: #9afccf;
}
.topicLabel.sentiment\:positive .topicLabel__count {
  color: #2b6349;
  background-color: #9afccf;
}
.topicLabel.sentiment\:neutral {
  background-color: #fcebbc;
}
.topicLabel.sentiment\:neutral:hover {
  background-color: #fce6a9;
}
.topicLabel.sentiment\:neutral .topicLabel__count {
  color: #6d5f38;
  background-color: #fce6a9;
}
.topicLabel.sentiment\:negative {
  background-color: #fcc5bd;
}
.topicLabel.sentiment\:negative:hover {
  background-color: #fcb4aa;
}
.topicLabel.sentiment\:negative .topicLabel__count {
  color: #6d3f39;
  background-color: #fcb4aa;
}
.sourceBadge {
  display: inline-flex;
}
.sourceBadge__published,
.sourceBadge__host {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 1.83333333em;
  white-space: nowrap;
}
.sourceBadge__published {
  min-width: 2.5em;
  padding: 0 0.45em 0 0.667em;
  font-weight: bold;
  background-color: #355cdd;
  color: #fff;
  border-top-left-radius: 0.75em;
  border-bottom-left-radius: 0.75em;
}
.sourceBadge__host {
  margin-left: 0.08333333rem;
  padding: 0 1em 0 0.667em;
  font-weight: 500;
  background-color: #ccd8fc;
  color: #1c2c63;
  border-top-right-radius: 0.75em;
  border-bottom-right-radius: 0.75em;
}
.sourceBadge__domain {
  cursor: pointer;
}
.sourceBadge__domain:hover {
  text-decoration: underline;
}
.sourceBadge__author.is\:filterable:hover {
  text-decoration: underline;
  cursor: pointer;
}
.filterTagList {
  align-items: baseline;
  font-size: 0.8125em;
  padding: 0.92307692em 0 0em;
  flex-shrink: 0;
  color: white;
  gap: 0.61538462em;
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  overflow-y: visible;
}
@media (min-width: 768px) {
  .filterTagList {
    flex-flow: row wrap;
    overflow: visible;
  }
}
.filterTagList__label {
  font-size: 0.9375em;
  color: #1c2c63;
  font-weight: bold;
}
.filterTag {
  cursor: pointer;
  height: 1.75em;
  line-height: 1;
  display: flex;
  align-items: stretch;
  white-space: nowrap;
}
.filterTag > * {
  display: flex;
  align-items: center;
}
.filterTag__field {
  font-weight: 500;
  text-transform: capitalize;
  background: #5f82f3;
  color: #fff;
  padding-left: 0.625em;
  padding-right: 0.5em;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
}
.filterTag__value {
  background-color: #ccd8fc;
  color: #111d42;
  padding-left: 0.5em;
  padding-right: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}
.filterTag__value::after {
  font-family: 'headlinehunter-icons';
  content: "\e904";
  color: #355cdd;
  font-size: 1.16666667rem;
  line-height: 1;
  margin-left: 0.41666667rem;
}
.flag {
  display: inline-flex;
  align-items: center;
}
.count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75em;
  min-width: 2em;
  min-height: 2em;
  border-radius: 0.25em;
  background-color: #355cdd;
  color: #fff;
  font-weight: bold;
}
.pill {
  border-radius: 1.5em;
  padding: 0.375em 1em;
  font-size: 1em;
  font-weight: 500;
  color: #1c2c63;
  background-color: white;
  height: auto;
}
.pill.is\:disabled {
  color: rgba(28, 44, 99, 0.4);
}
.pill__icon {
  margin-right: 0.5em;
}
.pill__icon::before {
  font-size: 1.5em;
}
.pill__body {
  display: flex;
  flex-flow: column;
}
.pill__title {
  font-size: 0.875em;
  font-weight: bold;
}
.pill__label {
  font-size: 0.75em;
  white-space: normal;
}
.bigText {
  width: 100%;
  height: 100%;
  font-size: 1.3125em;
  line-height: 1.333;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.bigText strong {
  font-size: 1.33333333em;
  font-weight: 600;
  margin-bottom: 0.21428571em;
}
.bigText::before {
  display: block;
  text-align: center;
  font-size: 3.04761905em;
  margin-bottom: 0.28571429em;
}
.viewMain--splashScreen .bigText {
  margin-top: -2.28571429em;
}
.summaryWidget {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}
.summaryWidget__input {
  display: flex;
  width: 100%;
  margin-top: 0.66666667rem;
  padding: 0rem 0.66666667rem;
}
.summaryWidget__input .textInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  font-size: 1.08333333rem;
  line-height: 1.23076923;
}
.summaryWidget__input .textInput:focus {
  outline: none;
}
.summaryWidget__input .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 3.5rem;
  height: auto;
}
.summaryWidget__input .button:focus {
  outline: none;
  background-color: #355cdd;
}
.summaryWidget__input .button .spinner {
  margin-right: 0.16666667rem;
}
.summaryWidget__presets {
  display: flex;
  width: 100%;
  max-width: 90%;
  flex-flow: row wrap;
  justify-content: center;
  column-gap: 0.5em;
  margin-top: 1.33333333rem;
}
.summaryWidget__preset {
  white-space: nowrap;
  background-color: #ccd8fc;
  color: #1c2c63;
  padding: 0.33333333rem 1rem;
  border-radius: 1.5em;
  margin: 0.5rem 0rem;
  transition: background-color 0.2s ease-in-out;
  height: auto;
  font-size: 1.08333333rem;
  line-height: 1.07692308;
}
.summaryWidget__preset:hover {
  background-color: #9cb3fc;
  color: #1c2c63;
}
.summaryWidget__output {
  position: relative;
  font-size: 1.16666667rem;
  line-height: 1.5;
  width: 100%;
}
.summaryWidget__output hr {
  margin: 1rem auto;
  border-color: #e9eefc;
  background-color: #e9eefc;
  height: 1px;
  border-width: 0;
  width: 80%;
}
.summaryWidget__output.is\:writing .markdown > ul:last-child li:last-child::after {
  content: "|";
  animation: blinking-cursor 1s linear infinite;
}
.summaryWidget__output.is\:writing .markdown > *:not(ul):last-child::after {
  content: "|";
  animation: blinking-cursor 1s linear infinite;
}
.summaryWidget__resultActions {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
}
@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.summaryCollection {
  height: 100%;
}
.summaryCollection__title {
  font-size: 1.75em;
  line-height: 1;
  font-weight: bold;
  padding-bottom: 0.85714286em;
  padding-left: 0.375em;
}
.summaryCollection__items {
  padding-bottom: 2em;
}
.summaryCollection__item + .summaryCollection__item {
  margin-top: 1.5em;
}
@media (min-width: 768px) {
  .summaryCollection__items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto-fit, minmax(28rem, 1fr));
    grid-gap: 1em;
  }
  .summaryCollection__item {
    margin-top: 0 !important;
  }
}
@media (min-width: 1600px) {
  .summaryCollection__items {
    height: calc(100% - 3.25em);
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: true;
}
@keyframes rotate-angleprop-clockwise {
  0% {
    --angle: 0deg;
  }
  100% {
    --angle: 360deg;
  }
}
@keyframes rotate-element-clockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes summaryCard-enter {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.summaryCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  background: #fff;
  box-shadow: 0 0.1em 0.25em rgba(37, 59, 133, 0.05), 0 0.25em 0.45em rgba(37, 59, 133, 0.05);
  border-radius: 1.5em;
  opacity: 0;
  animation: summaryCard-enter 0.2s ease-in forwards;
  position: relative;
}
.summaryCard::before {
  content: "";
  display: inline-block;
  position: absolute;
  pointer-events: none;
  inset: 0;
  border-radius: 1.5em;
  padding: 2px;
  background: linear-gradient(var(--angle), #fff, #5f82f3);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  animation: rotate-angleprop-clockwise 3.5s linear infinite forwards;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
.summaryCard.is\:writing::before {
  opacity: 1;
}
.summaryCard__header {
  padding: 1.25em 1.5em 0em 1.75em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.summaryCard__headerActions {
  display: flex;
  flex-flow: row wrap;
  align-self: flex-start;
}
.summaryCard__headerAction::before {
  transition: color 600ms ease-out;
}
.summaryCard__header .spinner {
  height: 1.75em;
}
.summaryCard__headerMenu {
  background-color: transparent;
  color: #355cdd;
  height: 2em;
  transition: color 600ms ease-out;
  border-radius: 0.45em;
}
.summaryCard__headerMenu.is\:active {
  background-color: #5f82f3;
}
.summaryCard__headerMenu.is\:active:before {
  color: #fff;
}
.summaryCard.is\:writing .summaryCard__headerAction::before,
.summaryCard.is\:writing .summaryCard__headerMenu::before {
  color: #5f82f3;
}
.summaryCard__title {
  transition: opacity 1s ease-out;
  margin-left: -0.5em;
}
.summaryCard__title > a {
  color: #2e4cb1;
  text-decoration: none;
}
.summaryCard__title > a:hover {
  color: #5f82f3;
  text-decoration: underline;
}
.summaryCard__titleStream {
  font-size: 1.1875em;
  color: #2e4cb1;
  line-height: 1;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.375em;
}
.summaryCard__titleIcon {
  width: 1.14285714em;
}
.summaryCard__titleSub {
  font-size: 0.875em;
  color: #809dfc;
  margin-left: 2em;
}
.summaryCard__body {
  padding: 0.75em 1.5em 0.75em;
  justify-self: stretch;
  flex: 1 1 100%;
  overflow: hidden;
  opacity: 1;
  transition: opacity 1s ease-out;
}
.summaryCard.is\:writing .summaryCard__body {
  opacity: 0.6;
}
.summaryCard__bodyText {
  font-size: 0.875em;
  line-height: 1.5;
}
.summaryCard__footer {
  display: flex;
  justify-content: center;
  padding: 0.5em 1.5em 1.125em;
}
.summaryCard__footer > a {
  color: #809dfc;
  text-decoration: none;
}
.summaryCard__footer > a:hover {
  color: #355cdd;
  text-decoration: underline;
}
.summaryCard--settings {
  justify-content: center;
  align-items: center;
  padding: 3em 0;
  position: relative;
  animation: none;
  opacity: 1;
}
.summaryCard--settings > * {
  width: 80%;
  padding: 0.5em 1.5em;
}
.summaryCard--settings .summaryCard__deleteAction {
  width: auto;
  position: absolute;
  left: 0.5em;
  top: 1.125em;
}
.summaryCard--settings .summaryCard__body,
.summaryCard--settings .summaryCard__footer {
  padding: 0 1.5em;
}
.summaryCard--settings .summaryCard__body {
  flex: 0 1 auto;
}
.summaryCard--settings .summaryCard__body > .select {
  width: 100%;
  margin-top: 0.5em;
}
.summaryCard--settings .summaryCard__footer {
  margin-top: 0.5em;
}
.summaryCard--settings .summaryCard__footer > .button {
  flex: 1 1 100%;
}
.summaryCard--placeholder {
  justify-content: center;
  align-items: center;
  border: 2px dashed #809dfc;
  background-color: transparent;
  box-shadow: none;
  transition: background-color 0.3s ease-in-out;
}
.summaryCard--placeholder:hover {
  background-color: rgba(204, 216, 252, 0.5);
}
.summaryCard__placeholderAction {
  flex-flow: column nowrap;
  align-items: center;
  height: auto;
  color: #9cb3fc;
  width: 100%;
  height: 100%;
  padding: 3em 1.5em;
  text-align: center;
  border-radius: 1.5em;
}
@media (min-width: 1024px) {
  .summaryCard__placeholderAction {
    padding: 25% 1.5em;
  }
}
.summaryCard__placeholderAction::before {
  font-size: 3em;
  margin-right: 0 !important;
  margin-bottom: 0.16666667em;
}
.summaryCard__placeholderAction:hover {
  color: #355cdd;
}
.summaryCard__placeholderAction:focus {
  border: none;
  outline: none;
  background-color: rgba(204, 216, 252, 0.5) !important;
}
.summaryCard:nth-child(2) {
  animation-delay: 0.3s;
}
.summaryCard:nth-child(3) {
  animation-delay: 0.6s;
}
.summaryCard:nth-child(4) {
  animation-delay: 0.9s;
}
.summaryCard:nth-child(5) {
  animation-delay: 1.2s;
}
.summaryCard:nth-child(6) {
  animation-delay: 1.5s;
}
.summaryCard.is\:mounted {
  animation: none;
  opacity: 1;
}
.summaryCard.is\:writing .summaryItemContent:hover .summaryItemContent__summary {
  text-decoration: none;
}
.summaryItem {
  position: relative;
}
.summaryItem:hover {
  z-index: 400;
}
.summaryItem.is\:open .summaryItemContent__summary {
  -webkit-line-clamp: 4;
  cursor: pointer;
}
.summaryItemContent {
  padding-left: 1.625em;
}
.summaryItemContent:hover .summaryItemContent__summary {
  -webkit-line-clamp: 4;
  cursor: pointer;
  text-decoration: underline;
}
.summaryItemContent__title {
  font-weight: bold;
  display: none;
  padding-right: 0.375em;
}
.summaryItemContent__summary {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.summaryItemContent__summary:before {
  content: "•";
  position: absolute;
  left: 0.125em;
}
.summaryItem + .summaryItem .summaryItemContent {
  padding-top: 0.5em;
}
.summaryItemPopover {
  outline: none;
  width: var(--radix-popover-trigger-width);
  font-size: 0.75em;
  padding: 1em;
  background-color: #fff;
  color: #1c2c63;
  box-shadow: 0em 0em 0.2em rgba(17, 29, 66, 0.1), 0em 0.5em 0.25em rgba(37, 59, 133, 0.1);
  border-radius: 0.5em;
}
.summaryItemPopover__title {
  font-weight: bold;
  margin-bottom: 0.5em;
}
.summaryItemPopover__headline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.summaryItemPopover__headline + .summaryItemPopover__headline {
  margin-top: 0.5em;
}
.summaryItemPopover__url {
  overflow: hidden;
  text-overflow: ellipsis;
}
.summaryItemPopover .sourceBadge {
  pointer-events: none;
  font-size: 0.85em;
  margin-right: 0.5em;
}
.summaryReference {
  position: relative;
  display: inline-block;
  cursor: pointer;
  background-color: #ccd8fc;
  opacity: 1;
  transition: opacity 0.2s;
  border-radius: 1.5em;
  padding: 0 0.15em;
}
.summaryReference:hover {
  z-index: 400;
}
.summaryReference button {
  background-color: transparent;
  padding-block: 2px;
  border-radius: none;
  color: #1c2c63;
}
.summaryReference:hover {
  background-color: #9cb3fc;
}
.summaryReference.is\:open {
  background-color: #9cb3fc;
}
.summaryReference.is\:loading {
  opacity: 0.2;
}
.summaryReferencePopover {
  outline: none;
  width: var(--radix-popover-trigger-width);
  font-size: 0.75em;
  padding: 1em;
  background-color: #fff;
  color: #1c2c63;
  box-shadow: 0em 0em 0.2em rgba(17, 29, 66, 0.1), 0em 0.5em 0.25em rgba(37, 59, 133, 0.1);
  border-radius: 0.5em;
  min-width: 36rem;
}
.summaryReferencePopover__title {
  font-weight: bold;
  margin-bottom: 0.5em;
}
.summaryReferencePopover__headline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.summaryReferencePopover__headline + .summaryReferencePopover__headline {
  margin-top: 0.5em;
}
.summaryReferencePopover__url {
  overflow: hidden;
  text-overflow: ellipsis;
}
.summaryReferencePopover .sourceBadge {
  pointer-events: none;
  font-size: 0.85em;
  margin-right: 0.5em;
}
.newTopicsWidgets {
  background: none !important;
  box-shadow: none !important;
  padding-bottom: 3em;
}
.newTopicsWidgets__toolbarActions {
  padding: 0.375em 0;
  display: flex;
  gap: 0.25em;
  justify-content: flex-end;
}
.newTopicsWidget .tooltip__data,
.widget--topics .tooltip__data {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.newTopicsWidget table,
.widget--topics table {
  font-size: 0.875em;
}
.newTopicsWidget .dataTable__head,
.widget--topics .dataTable__head {
  font-weight: bold;
  border-bottom: 1px solid #d5d6db;
}
.newTopicsWidget .dataTable__head td,
.widget--topics .dataTable__head td {
  padding: 0.333em 0.666em;
}
.newTopicsWidget .dataTable__body tr:first-child td,
.widget--topics .dataTable__body tr:first-child td {
  padding-top: 0.75em;
}
.newTopicsWidget .dataTable__body tr:nth-child(even),
.widget--topics .dataTable__body tr:nth-child(even) {
  background-color: #f9f9fb;
}
.newTopicsWidget .dataTable__value,
.widget--topics .dataTable__value {
  padding: 0.333em 0.666em;
}
.newTopicsWidget .dataTable__value--numeric,
.widget--topics .dataTable__value--numeric {
  text-align: right;
  padding: 0.333em 1em 0.333em 0.75em;
}
.switch {
  --border: none, none;
  --background: #9cb3fc;
  --thumb-background: #fff;
  --box-shadow: none, none;
  --thumb-border: none, none;
  --thumb-box-shadow: none, none;
  --icon: IndianRed, #222;
  --height: 1.75em;
  --padding: 0.1875em;
  --border-radius: 1.75em;
  --thumb-font-size: 0.75em;
  --thumb-width: 1.375em;
  --thumb-height: 1.375em;
  --thumb-gap: 0.1875em;
  --thumb-border-radius: 1.375em;
  display: flex;
  align-items: center;
}
.switch [data-state="checked"] {
  --background: #5f82f3;
  --border: none, none;
  --thumb-border: none, none;
  --icon: #fff, #fff;
}
.switch--labeled {
  --color: #777, #555;
  --border: none, none;
  --box-shadow: none, 0 0 0.25em rgba(0, 0, 0, 0.75);
  --thumb-border: none, none;
  --thumb-box-shadow: none, 0 0 0.5em rgba(0, 0, 0, 0.25);
  --icon: IndianRed, #222;
}
.switch--labeled [data-state="checked"] {
  --color: #fff, steelblue;
  --border: none, none;
  --thumb-border: none, none;
  --icon: cornflowerblue, #fff;
}
.switch__toggle {
  display: block;
  --toggleWidth: calc(var(--thumb-width)*2 + var(--padding)*2 + var(--thumb-gap));
  width: var(--toggleWidth);
  height: var(--height);
  padding: var(--padding) 0;
  background: var(--background);
  border: var(--border);
  border-radius: var(--border-radius);
  box-shadow: inset var(--box-shadow);
}
.switch__toggleThumb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--thumb-width);
  height: var(--thumb-height);
  background: var(--thumb-background);
  border: var(--thumb-border);
  border-radius: var(--thumb-border-radius);
  box-shadow: var(--thumb-box-shadow);
  transform: translateX(var(--thumb-off-position));
  --thumb-off-position: var(--padding);
  --thumb-on-position: calc(var(--thumb-width) + var(--thumb-gap) + var(--padding));
  transition: transform 0.1s ease-in-out;
}
.switch__toggleThumb::before {
  color: var(--icon);
  font-size: var(--icon-size);
}
.switch:not(.switch--labeled) [data-state=checked] .switch__toggleThumb {
  transform: translateX(var(--thumb-on-position));
}
.switch__label {
  color: var(--background);
  margin: 0 0.5em 0 0.25em;
  white-space: nowrap;
  display: flex;
}
.switch__label [class*="icon"] {
  margin-right: 0.25em;
}
.switch__label [class*="iconOnly"] {
  display: flex;
  align-items: center;
}
.switch [data-state="checked"] + .switch__label {
  --background: #5f82f3;
}
.switch--labeled {
  --height: 2.625em;
  --padding: 0.3125em;
  --border-radius: 0.5em;
  --thumb-font-size: 0.75em;
  --thumb-width: 3em;
  --thumb-height: 2em;
  --thumb-gap: 0.625em;
  --thumb-border-radius: 0.375em;
  --label-checked: 'ON';
  --label-unchecked: 'OFF';
}
.switch--labeled [data-label-checked] {
  --label-checked: attr(data-label-checked);
}
.switch--labeled [data-label-unchecked] {
  --label-unchecked: attr(data-label-unchecked);
}
.switch--labeled .switch__toggle {
  position: relative;
  display: grid;
  grid-template-columns: var(--thumb-width) var(--thumb-width);
  grid-gap: 0 var(--thumb-gap);
}
.switch--labeled .switch__toggle::before,
.switch--labeled .switch__toggle::after {
  color: var(--color);
  text-align: center;
  align-self: center;
}
.switch--labeled .switch__toggle::before {
  content: var(--label-checked);
}
.switch--labeled .switch__toggle::after {
  content: var(--label-unchecked);
}
.switch--labeled .switch__toggleThumb {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(var(--thumb-off-position));
}
.switch--labeled [data-state=checked] .switch__toggleThumb {
  transform: translateY(-50%) translateX(var(--thumb-on-position));
}
.orderMenu .contextMenu__item:not(.is\:active)::before {
  opacity: 0;
}
.customStreamForm__row {
  display: flex;
  gap: 1em;
  flex-flow: row wrap;
  margin-bottom: 0.66666667rem;
  margin-top: 0.66666667rem;
}
.customStreamForm__section {
  width: 100%;
}
.customStreamForm__sectionTitle {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1.25rem;
  flex-flow: row wrap;
}
.customStreamForm__sectionTitle h3 {
  font-size: 1.125em;
  font-weight: 700;
}
.customStreamForm__switches {
  display: flex;
  margin-left: auto;
  font-size: 1em;
}
.customStreamForm__switches .switch {
  --height: 1.5em;
  --thumb-width: 1em;
  --thumb-height: 1em;
}
.customStreamForm .field {
  flex: 0.5;
}
.customStreamForm .field__label {
  padding-top: 0;
}
.customStreamForm .field--emoji {
  flex: 0 0 4em;
}
.customStreamForm .field--text {
  flex: auto;
}
.customStreamForm .field--text .button {
  border: 0;
}
.customStreamForm .field--text .button:focus,
.customStreamForm .field--text .button:focus-visible {
  outline: none;
  background-color: #ccd8fc;
}
.customStreamForm .searchTokens {
  margin-top: 1em;
}
.customStreamForm .searchTokens__heading {
  font-size: 1.25rem;
  line-height: 1.26666667;
}
.customStreamForm .searchTokens__section {
  margin-top: 0.5em;
}
.customStreamBtn {
  width: 12.5em;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: 2rem;
  z-index: 9999;
  box-shadow: 0 0 1em rgba(37, 59, 133, 0.07), 0 0.25em 0.25em rgba(17, 29, 66, 0.15), 0 0.75em 0.75em rgba(28, 44, 99, 0.1);
  border: 1px solid #eaeaed;
}
.customStreamBtn .icon {
  color: #fd4f01;
}
.customStreamBtn:hover .icon {
  color: unset;
}
.customStreamBtn:active,
.customStreamBtn:hover,
.customStreamBtn.is\:disabled:active,
.customStreamBtn.is\:disabled:hover {
  transform: translate3d(-50%, 0, 0) !important;
}
.customStreamBtn.is\:disabled:hover {
  background-color: #fff;
}
.customStreamBtn.is\:disabled {
  cursor: not-allowed;
  pointer-events: auto;
}
.customStreamBtn.is\:disabled .icon {
  color: unset;
}
.customStreamModal {
  max-width: 40em;
  display: flex;
  flex-flow: column;
  overflow: auto;
}
.customStreamModal .dialog__title {
  font-size: 2rem;
  line-height: 1.08333333;
}
.customStreamModal .dialog__content {
  margin-top: 0.25em;
}
.customStreamModal .dialog__close {
  display: block;
  background-color: rgba(171, 175, 184, 0.2);
  color: #3245B9;
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  border-radius: 50%;
  transition: all 0.3s;
  font-weight: 900;
}
.customStreamModal .dialog__close:focus {
  outline: none;
  background-color: rgba(171, 175, 184, 0.4);
}
.customStreamModal .dialog__close:focus-visible {
  outline: revert;
  background-color: rgba(171, 175, 184, 0.4);
}
.customStreamModal .dialog__close::before {
  font-size: 1.5em;
  font-weight: 900;
}
.customStreamModal .dialog__close:hover {
  color: #355cdd;
  background-color: rgba(171, 175, 184, 0.4);
}
.customStreamModal__stickyHeader {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.customStreamModal__submit {
  margin-top: 1rem;
}
.customStreamModal .dialog__error {
  color: #b24334;
}
@media (min-width: 768px) {
  .customStreamModal {
    min-height: 70vh;
    height: auto;
  }
}
@media (min-width: 1366px) {
  .customStreamModal {
    padding: 2.625em 3em 2.25em 3em;
    min-height: 56rem;
  }
  .customStreamModal .dialog__close {
    top: -1.25em;
    right: -1.875em;
  }
}
.emojiSelectMenu {
  background-color: #fff;
  border-radius: 0.8em;
  box-shadow: 0 0 1em rgba(37, 59, 133, 0.07), 0 0.25em 0.25em rgba(17, 29, 66, 0.15), 0 0.75em 0.75em rgba(28, 44, 99, 0.1);
}
.emojiSelectMenu__trigger {
  display: inline-flex;
  position: relative;
  font-size: 1em;
  line-height: 1;
  padding: 0.5em 0.75em;
  height: 2.625em;
  border: 0.08333333rem solid #5f82f3;
  border-radius: 0.5em;
  background-color: #fff;
}
.emojiSelectMenu__trigger.input\:small {
  height: 2.33333333rem;
  padding: 0.25em 0.375em;
  font-size: 1rem;
  line-height: 1.33333333;
  border-radius: 0.66666667rem;
}
.emojiSelectMenu__value {
  font-size: 1.75rem;
  line-height: 1;
}
.select--emoji em-emoji-picker {
  --background-rgb: 255, 0, 0;
}
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5,
.markdown h6 {
  font-weight: bold;
}
* + .markdown h1,
* + .markdown h2,
* + .markdown h3,
* + .markdown h4,
* + .markdown h5,
* + .markdown h6 {
  margin-top: 1em;
}
.markdown strong {
  font-weight: bold;
}
.markdown i {
  font-style: italic;
}
.markdown u {
  text-decoration: underline;
}
* + .markdown ul,
* + .markdown ol,
* + .markdown p {
  margin-top: 0.5em;
}
.markdown ol,
.markdown ul {
  list-style: outside;
  margin-left: 1.5em;
}
.markdown li {
  padding-left: 0.25em;
}
.markdown li::marker {
  color: #5f82f3;
}
.markdown li:not(:first-child) {
  margin-top: 0.5em;
}
.toasts {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 400;
  width: 100%;
  display: flex;
}
.toast {
  position: absolute;
  right: 1.33333333rem;
  bottom: 1.33333333rem;
  padding: 0.66666667rem 0.66666667rem 0.66666667rem 0.33333333rem;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  background-color: #fff;
  transition: all 230ms cubic-bezier(0.21, 1.02, 0.73, 1);
}
.toast--success {
  background-color: #edfcf6;
  color: #2b6349;
}
.toast--error {
  background-color: #fcf1f0;
  color: #b24334;
}
.toast__message {
  padding: 0rem 0.66666667rem;
}
.toast__message::before {
  margin-right: 0.66666667rem;
}
.searchInput {
  min-width: 3em;
  background: none;
  border: none;
  padding: 0;
  color: #1c2c63;
  padding-left: 0;
  padding-right: 0;
  height: 2.625em;
  display: flex;
  align-items: center;
}
.searchInput.has\:value .input {
  padding-right: 1rem;
}
.searchInput .icon {
  display: none;
}
.searchInput .input {
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 767px) {
  .searchInput .input {
    padding-left: 0.5em;
  }
}
.searchInput__placeholder {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 0.375em;
  height: 2.625em;
  font-size: 1em;
  color: #abafb8;
  user-select: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}
.searchInput__clear {
  position: sticky;
  right: 0.25em;
  margin-left: auto;
}
.searchInput__clear::before {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: bold;
  background-color: #ccd8fc;
  border-radius: 50%;
  width: 1.5rem;
  padding: 0.05em;
  color: #1c2c63;
}
.searchInput:focus {
  outline: none;
}
.searchInput::before,
.searchInput__icon {
  margin-left: 0.5em;
}
@media (max-width: 767px) {
  .searchInput::before,
  .searchInput__icon {
    display: none;
  }
}
.searchInput .spinner {
  margin-right: 0.66666667rem;
}
@media (min-width: 1024px) {
  .searchInput .icon {
    display: inline-flex;
  }
}
.searchInput .searchTagList {
  padding-left: 0;
  padding-right: 0;
}
.searchInput .searchTagList:first-of-type {
  padding-left: 0.66rem;
}
.searchInput .searchTagList__items {
  flex-flow: row nowrap;
}
.searchSubmit {
  height: 100%;
  border-radius: 0.75em;
  margin-right: -0.1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 1.33333333rem;
  padding-right: 0.83333333rem;
}
.searchSubmit::before {
  font-size: 1.66666667rem;
  line-height: 1.2;
  margin-right: 0.1em !important;
}
.searchSubmit__label {
  display: none;
}
@media (min-width: 1024px) {
  .searchSubmit {
    padding-left: 1.33333333rem;
    padding-right: 1.33333333rem;
  }
  .searchSubmit__label {
    display: flex;
  }
  .searchSubmit::before {
    margin-right: 0.33em !important;
  }
}
.proBadge {
  font-weight: bold;
  text-transform: uppercase;
  background-color: #5f82f3;
  color: #fff;
  font-size: 0.70833333rem;
  line-height: 2.11764706;
  padding: 0rem 0.33333333rem;
  border-radius: 0.25em;
}
.searchModeMenuTrigger {
  width: 3.75rem;
  flex: 0 0 3.75rem;
  height: 100%;
  border-bottom-left-radius: 0.8em;
  border-top-left-radius: 0.8em;
  background: #5f82f3;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.5rem 0 0.8rem;
}
.searchModeMenuTrigger__proBadge {
  background-color: #fff;
  color: #5f82f3;
}
.searchModeMenuTrigger__chevron {
  display: none;
}
@media (min-width: 768px) {
  .searchModeMenuTrigger {
    flex-basis: 5rem;
    width: 5rem;
  }
  .searchModeMenuTrigger__chevron {
    display: inline-flex;
  }
}
.searchModeMenu {
  max-width: 14rem;
  padding: 0;
}
.searchModeMenu__proBadge {
  display: inline-block;
}
.searchModeMenu .contextMenu__item {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.searchModeMenu .contextMenu__item::before {
  top: 0;
  margin-right: 1rem;
  margin-left: 0.33333333rem;
}
.contextMenu__item .searchModeMenu__proBadge {
  margin-right: 0.66666667rem;
}
.contextMenu__item:hover .searchModeMenu__proBadge {
  background-color: #ccd8fc;
  color: #5f82f3;
}
.searchTokens {
  flex-flow: row wrap;
}
.searchTokens__section {
  flex: 1 1 22.5rem;
  margin-top: 0.33em;
}
.searchTokens__heading {
  font-size: 1.08333333rem;
  line-height: 1.23076923;
  font-weight: 500;
  margin-bottom: 0.33em;
  display: flex;
  align-items: center;
  color: #444;
}
.searchTokens .searchTagList {
  border: 1px solid #809dfc;
  border-radius: 0.5em;
}
.searchPopover {
  position: absolute;
  top: 2.2rem;
  left: -3.5rem;
  right: -0.89rem;
  margin-top: 2rem;
  padding-bottom: 0.55em;
  background-color: white;
  display: none;
  border-left: 0.0625em solid #809dfc;
  border-right: 0.0625em solid #809dfc;
  border-bottom: 0.0625em solid #809dfc;
  border-bottom-left-radius: 0.8em;
  border-bottom-right-radius: 0.8em;
}
.searchPopover div:first-of-type {
  padding-left: 1em;
  padding-right: 1em;
}
.searchPopover div:last-of-type {
  padding-right: 1em;
  padding-left: 1em;
}
.searchPopover::before {
  content: '';
  display: block;
  width: 100%;
  border-top: 0.0625em solid #ccd8fc;
  margin-bottom: 0.55em;
  padding-bottom: 0.25em;
}
@media (min-width: 768px) {
  .searchPopover {
    top: 1em;
    left: 2.2em;
    right: 2.65em;
    margin-left: 2rem;
  }
  .searchPopover::before {
    margin: 0 auto;
    width: calc(100% - 1.75em);
    border-color: #809dfc;
  }
}
@media (min-width: 1024px) {
  .searchPopover {
    width: auto;
    right: 8.8rem;
  }
  .searchPopover::before {
    margin: 0 auto;
    width: calc(100% - 1.75em);
  }
}
.searchSuggestions__took {
  font-size: 1rem;
  line-height: 1.33333333;
  font-weight: bold;
  color: #757a85;
  position: absolute;
  right: 2rem;
  top: 1rem;
}
.searchSuggestions__item {
  padding: 0.2em 1.5rem;
  font-size: 1.5rem;
  line-height: 1.33333333;
}
.searchSuggestions__item b {
  font-weight: bold;
  color: #5f82f3;
}
.searchSuggestions__item.is\:highlighted {
  background-color: #e9eefc;
}
.searchSuggestions__item:hover {
  background-color: #ccd8fc;
  cursor: default;
}
@media (min-width: 1024px) {
  .searchSuggestions__item {
    padding: 0.2em 2.5rem !important;
  }
}
.searchTagList {
  display: flex;
  flex-flow: row wrap;
  padding: 0.25em 0.5em 0.25em 0.5em;
}
.searchTagList__items {
  display: none;
  flex-flow: row wrap;
  align-items: center;
  flex: 0 1 auto;
  margin-bottom: 0.25em;
}
.searchTagList__item {
  display: inline-flex;
  flex-flow: row nowrap;
  margin-top: 0.25em;
  max-width: 100%;
  height: 1.375em;
  margin-right: 0.5em;
  border-radius: 0.45em;
  transition: color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), border-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955), background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  background-color: #ccd8fc;
  white-space: nowrap;
}
.searchTagList__itemPrefix {
  font-size: 1rem;
  line-height: 1.33333333;
  background-color: #5f82f3;
  padding: 0.25em 0.33em 0.25em 0.33em;
  font-weight: bold;
  height: 100%;
  color: #fff;
  border-top-left-radius: 0.45em;
  border-bottom-left-radius: 0.45em;
}
.searchTagList__itemLabel {
  font-size: 1rem;
  line-height: 1.33333333;
  height: 100%;
  padding: 0.25em 0.33em;
  background-color: #ccd8fc;
  border-top-left-radius: 0.45em;
  border-bottom-left-radius: 0.45em;
}
.searchTagList__itemClear {
  background: #ccd8fc;
  font-weight: bold;
  border-top-right-radius: 0.45em;
  border-bottom-right-radius: 0.45em;
  width: 1.75rem;
  color: #1c2c63;
  padding: 0.25em;
  transition: all 0.12s ease-in-out;
}
.searchTagList__itemClear:hover {
  background-color: #809dfc;
  color: #fff;
  cursor: pointer;
}
.searchTagList__itemClear::before {
  font-size: 1.16666667rem;
  line-height: 1;
  padding-right: 0.25em;
}
.searchTagList__input {
  display: inline-flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  padding-top: 0.15em;
  padding-bottom: 0.15em;
}
.searchTagList__inputText {
  display: inline-flex;
  flex: 1;
  width: 100%;
  border: none;
  border-radius: 0;
  padding: 0.2em;
  height: 1.5em;
}
.searchTagList__inputText:focus {
  outline: none;
}
.searchTagList__inputAdd {
  opacity: 1;
  transition: opacity 0.12s ease-in-out;
  border-radius: 50%;
  height: 2rem;
  width: 2rem !important;
}
.searchTagList__inputAdd::before {
  font-size: 1.16666667rem;
  line-height: 1;
}
.searchTagList__inputAdd[disabled] {
  opacity: 0;
}
.searchTagList.has\:value .searchTagList__items {
  display: flex;
}
.longtextSummary {
  max-width: 60em;
  height: 95vh;
  max-height: 95vh;
  width: 95vw;
  display: flex;
  flex-flow: column;
  overflow: auto;
}
.longtextSummary .aiBadge {
  display: inline-flex;
  color: #fd4f01;
  border: 1px solid #fd4f01;
  border-radius: 0.75em;
  font-size: 0.83333333rem;
  line-height: 1.8;
  padding: 0rem 0.5rem;
  text-transform: uppercase;
  align-self: flex-start;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.longtextSummary .dialog__title {
  font-size: 2.16666667rem;
  line-height: 1.23076923;
  padding-right: 0.5em;
}
.longtextSummary.has\:longTitle .dialog__title {
  font-size: 1.66666667rem;
  line-height: 1.3;
}
.longtextSummary.has\:veryLongTitle .dialog__title {
  font-size: 1.16666667rem;
  line-height: 1.42857143;
}
.longtextSummary .dialog__description {
  font-size: 1.08333333rem;
  line-height: 1.84615385;
  color: #355cdd;
}
.longtextSummary .dialog__content {
  position: relative;
  flex: 1;
  display: flex;
  flex-flow: column;
}
.longtextSummary__content {
  flex: 1;
}
.longtextSummary .markdown p {
  margin: 0rem 0rem 0.66666667rem 0rem;
}
.longtextSummary__stickyHeader {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.longtextSummary .dialog__close {
  display: block;
  background-color: rgba(171, 175, 184, 0.2);
  color: #3245B9;
  height: 3rem;
  width: 3rem;
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  border-radius: 50%;
  transition: all 0.3s;
  font-weight: 900;
}
.longtextSummary .dialog__close:focus {
  outline: none;
  background-color: rgba(171, 175, 184, 0.4);
}
.longtextSummary .dialog__close:focus-visible {
  outline: revert;
  background-color: rgba(171, 175, 184, 0.4);
}
.longtextSummary .dialog__close::before {
  font-size: 1.5em;
  font-weight: 900;
}
.longtextSummary .dialog__close:hover {
  color: #355cdd;
  background-color: rgba(171, 175, 184, 0.4);
}
.longtextSummary .dialog__actions {
  justify-content: flex-start;
  flex-flow: row wrap;
  gap: 0.375em;
  position: sticky;
  bottom: 0;
}
.longtextSummary .dialog__actions a {
  color: #fff;
}
.longtextSummary .copyMenu {
  margin: 0;
}
.longtextSummary .spinner {
  position: absolute;
  width: 100%;
  bottom: 50%;
}
.longtextSummary.is\:writing .markdown > ul:last-child li:last-child::after {
  content: "|";
  animation: blinking-cursor 1s linear infinite;
}
.longtextSummary.is\:writing .markdown > *:not(ul):last-child::after {
  content: "|";
  animation: blinking-cursor 1s linear infinite;
}
@media (min-width: 768px) {
  .longtextSummary {
    min-height: 70vh;
    height: auto;
  }
}
@media (min-width: 1366px) {
  .longtextSummary {
    padding: 2.625em 3em 2.25em 3em;
    min-height: 56rem;
  }
  .longtextSummary .dialog__close {
    top: -1.25em;
    right: -1.875em;
  }
}
.copyMenu {
  border-radius: 0.8em;
  overflow: hidden;
}
.copyMenuPopover {
  z-index: 400;
  background-color: #e9eefc;
  max-width: 13.125em;
}
.copyMenuPopover .contextMenu__item {
  white-space: nowrap;
}
.view {
  overflow-x: hidden;
  background-color: #fff;
  background-image: radial-gradient(200% 10% at 0% 95%, #FFFFFFFF 0%, #FFFFFF00 100%), radial-gradient(25% 40% at 100% 60%, #d4dbfa 0%, #FFFFFF00 100%), radial-gradient(25% 40% at 5% 50%, #c0d3fdff 0%, #FFFFFF00 100%), radial-gradient(150% 50% at 50% 70%, #dcebfd 0%, #FFFFFFFF 100%);
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px;
}
.viewHeader {
  height: 3.75em;
  position: relative;
  z-index: 200;
}
.viewHeader__toolbar {
  height: 3.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.viewHeader__masthead {
  flex-shrink: 0;
}
@media (min-width: 1366px) {
  .viewHeader__masthead {
    width: 25.5em;
  }
}
.view .extendedSearch {
  display: none;
  visibility: hidden;
}
.viewMenu,
.viewMain,
.viewSide {
  max-height: 100%;
  overflow-y: auto;
}
.viewMain {
  padding: 2em 1em 0;
}
.viewSide {
  padding: 2em 1em;
}
@media (min-width: 1024px) {
  .viewSide {
    padding: 2em 1em 2em 1em;
  }
}
@media (max-width: 1023px) {
  .viewBody {
    height: calc(100vh - 3.75em);
    display: grid;
    grid-template-columns: 95vw 100vw 100vw;
    grid-template-rows: 2.5rem 1fr;
    grid-template-areas: "menu controls controls" "menu main side";
    transform: translateX(-95vw);
  }
  .viewBody.show\:main {
    transform: translateX(-95vw);
  }
  .viewBody.show\:side {
    transform: translateX(-195vw);
  }
  .viewBody.show\:menu {
    transform: translateX(0);
  }
  .viewMenu {
    grid-area: menu;
  }
  .viewMain {
    grid-area: main;
  }
  .viewSide {
    grid-area: side;
  }
  .viewControls {
    grid-area: controls;
    max-width: 50%;
  }
  .show\:side .viewControls {
    transform: translateX(100vw);
  }
  .viewHeader.show\:extendedSearch {
    position: relative;
  }
  .viewHeader.show\:extendedSearch .extendedSearch {
    position: fixed;
    display: block;
    visibility: visible;
    z-index: 400;
    height: calc(100vh - 3.75em);
    overflow-y: auto;
  }
}
@media (min-width: 768px) {
  .viewHeader.show\:extendedSearch .extendedSearch {
    position: fixed;
    right: 0;
    left: 0;
    display: block;
    visibility: visible;
    z-index: 400;
    height: calc(100vh - 3.75em);
    overflow-y: auto;
  }
}
@media (min-width: 1024px) {
  .viewBody {
    height: calc(100vh - 3.75em);
    display: grid;
    grid-template-columns: 25em 60vw 40vw;
    grid-template-areas: "menu main side";
    transform: translateX(-25em);
  }
  .viewBody.show\:menu {
    transform: translateX(0);
  }
  .viewControls {
    display: none;
    visibility: hidden;
  }
  .viewMain--splashScreen {
    grid-column: 2 / span 2;
  }
  .viewHeader.show\:extendedSearch .extendedSearch {
    display: grid;
    visibility: visible;
    overflow-y: visible;
    position: fixed;
    z-index: 310;
    left: calc(3em + 1px);
    top: 3em;
    right: 0.5em;
    height: auto;
  }
  .viewHeader.show\:extendedSearch .extendedSearch::before {
    content: '';
    pointer-events: none !important;
    background-color: transparent !important;
    position: absolute;
    z-index: 300;
    top: -2.417em;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0.8em;
    box-shadow: 0 0.1em 0.25em rgba(37, 59, 133, 0.2), 0 0.5em 1.5em rgba(28, 44, 99, 0.2);
  }
  .viewHeader.show\:extendedSearch .viewHeader__searchBox {
    position: relative;
    z-index: 311;
  }
}
@media (min-width: 1366px) {
  .viewBody {
    grid-template-columns: 25em 1fr 28%;
    column-gap: 1.5em;
    transform: translateX(0);
  }
  .hide\:menu .viewBody {
    grid-template-columns: 5rem 60em 1fr;
  }
  .view.bookmarks .viewBody:not(.has\:viewSide) {
    grid-template-columns: 25em minmax(60em, 100%) 1fr;
  }
  .viewHeader.show\:extendedSearch .extendedSearch {
    left: 25.5em;
    top: 3em;
    right: 0.5em;
  }
}
.view--page {
  display: flex;
  flex-flow: column;
  background-color: #fff;
  margin-top: 0;
  min-height: 100vh;
  background-image: url('/images/wave.jpg');
  background-size: cover;
}
@media (max-width: 767px) {
  .view--page {
    background-image: none;
  }
}
@media (min-width: 768px) {
  .view--page {
    padding-bottom: 5.33333333rem;
  }
}
.view--page .viewMain {
  width: 100%;
  padding: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.login {
  width: 100%;
  max-width: 33.33333333rem;
  margin-bottom: 2.66666667rem;
  padding: 4.33333333rem;
}
.login__logo {
  max-width: 16rem;
  display: block;
  margin-bottom: 2.5rem;
}
.login__title {
  font-size: 1.66666667rem;
  line-height: 0.9;
  font-weight: 600;
}
.login__description {
  font-size: 1.16666667rem;
  line-height: 1.14285714;
  margin: 0.83333333rem 0rem 1.66666667rem 0rem;
  font-weight: 500;
}
.login__form {
  display: flex;
  flex-flow: column;
  align-items: stretch;
}
.login .field {
  display: flex;
  flex-flow: column;
  margin-bottom: 0.83333333rem;
}
.login .field__label {
  font-size: 1rem;
  line-height: 1.33333333;
  padding: 0.33333333rem 0rem 0.33333333rem;
  color: #595d69;
  font-weight: 600;
}
.login .field__input {
  background: rgba(255, 255, 255, 0.1);
}
.login__submit {
  margin-top: 1.33333333rem;
  background: #253b85;
  border-radius: 0.5rem;
}
.login__submit:hover {
  background: #355cdd;
}
.login__submit:focus {
  background: #355cdd;
  outline: 1px solid #355cdd;
  outline-offset: 0.1em;
}
@media (max-width: 767px) {
  .login {
    padding: 2rem;
    margin-bottom: 0rem;
  }
}
@media (min-width: 768px) {
  .login {
    margin-top: 2rem;
  }
}
.loginIntro {
  width: 100%;
  max-width: 33.33333333rem;
  margin-bottom: 2.66666667rem;
}
.loginSocials {
  padding: 2.66666667rem 2.66666667rem 2.66666667rem 2.66666667rem;
}
.loginSocials__title {
  font-size: 1.66666667rem;
  line-height: 0.9;
  font-weight: 600;
}
.loginSocials__items {
  display: flex;
  flex-flow: row wrap;
}
.loginSocials__items .button:focus {
  outline: 1px solid #355cdd;
  outline-offset: 0.1em;
}
.loginSocials__item {
  margin: 0rem 1.33333333rem 0rem 1.33333333rem;
}
.loginSocials__logo {
  height: 2rem;
}
@media (max-width: 1023px) {
  .view--account .viewBody {
    grid-template-rows: 0 1fr;
  }
}
@media (min-width: 1024px) {
  .view--account .viewBody {
    grid-template-columns: 25em 1fr;
    grid-template-areas: "menu main";
  }
}
.view--account .viewMain {
  width: 100%;
}
.account {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  gap: 4rem;
  padding: 2.33333333rem 1.83333333rem;
}
.account .input {
  width: 100%;
}
.account__title {
  font-size: 1.83333333rem;
  line-height: 1;
}
.account__section {
  max-width: 36rem;
  flex: 1 1 30rem;
}
.account__sectionTitle {
  font-size: 1.58333333rem;
  line-height: 1;
}
.account__form {
  display: flex;
  flex-flow: column;
}
.account__formSubmit {
  margin-top: 1.33333333rem;
  align-self: flex-end;
}
@media (min-width: 1024px) {
  .account__formSubmit {
    margin-top: 2rem;
  }
}
@media (min-width: 1024px) {
  .account {
    padding: 2.33333333rem 2.66666667rem;
  }
}
@media (min-width: 1366px) {
  .account {
    padding: 4rem 2.66666667rem;
  }
}
.view--dashboard .viewHeader__searchBox {
  margin-right: 0.625em;
}
@media (max-width: 1023px) {
  .view--dashboard .viewBody {
    grid-template-columns: 95vw 100vw;
  }
}
@media (min-width: 1024px) {
  .view--dashboard .viewBody {
    grid-template-columns: 25em 100vw;
  }
}
@media (min-width: 1366px) {
  .view--dashboard .viewBody {
    grid-template-columns: 25em 1fr;
  }
}
.resetAppearance {
  border: none;
  outline: none;
  width: 100%;
  background: none;
  border-radius: 0;
}
.coverParent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
